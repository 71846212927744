import gql from 'graphql-tag';

const COMMON_FIELDS = `
  email
  firstName
  lastName
  id
  isAdmin
  position
  roles
  brokerAssignInfo {
    settingCountAssignObjectsInDay
    settingCountObjectsInWork
  }
  contactInfo {
    telegram
  }
`;

const getQueries = (hasQueries) => {
  return hasQueries ? `queries {
    default
    filters {
      condition
      name
      value
      value2
    }
    id
    name
    __typename
    ... on Types__InternalAdmin__Query__FixedForUser {
      polygons {
        points {
          lat
          lng
        }
      }
    }
  }` : '';
};

export const mutation_create = gql`
  mutation mutation_create(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String
    $position: String,
    $roles: [InternalAdmin__User__RoleEnum!],
    $contactTelegram: String,
    $countObjectsAssignedInDay: Int,
    $countObjectsInWork: Int
  ) {
    userCreate(
      input: {
        email: $email,
        firstName: $firstName,
        lastName: $lastName,
        password: $password,
        position: $position,
        roles: $roles,
        contactTelegram: $contactTelegram,
        countObjectsAssignedInDay: $countObjectsAssignedInDay,
        countObjectsInWork: $countObjectsInWork
      }
    ) {
      user {
        ${COMMON_FIELDS}
      }
      password
      status
    }
  }
`;

export const mutation_update = gql`
  mutation mutation_update(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $password: String
    $position: String,
    $roles: [InternalAdmin__User__RoleEnum!],
    $contactTelegram: String,
    $countObjectsAssignedInDay: Int,
    $countObjectsInWork: Int
  ) {
    userUpdate(
      input: {
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        password: $password,
        position: $position,
        roles: $roles,
        contactTelegram: $contactTelegram,
        countObjectsAssignedInDay: $countObjectsAssignedInDay,
        countObjectsInWork: $countObjectsInWork
      }
    ) {
      user {
        ${COMMON_FIELDS}
      }
      status
    }
  }
`;

export const query_users = (hasQueries = true) => gql`
  query query_users(
    $first: Int,
    $fields: [InternalAdmin__User__FilterField!],
    $orders: [InternalAdmin__User__Order!],
    $search: InternalAdmin__User__Search
  ) {
    users(
      first: $first,
      filter: {
        fields: $fields,
        search: $search
      }
      orders: $orders,
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      nodes {
        ${COMMON_FIELDS}
        ${getQueries(hasQueries)}
      }
    }
  }
`;
