import queryInvestmentObjectPhones from '@/lib/queries/investment_object_phones.js';
import { apolloClient } from '@/lib/apollo';

const showPhones = (id) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: queryInvestmentObjectPhones,
        variables: {
          id
        },
        fetchPolicy: "no-cache"
      })
      .then(res => {
        const phones = res.data.investmentObjectPhones;

        resolve(phones);
      }).catch((err) => { reject(err) });
  });
}

export default showPhones;
