const getUrlParams = (url) => {
  const href = url || window.location.href;
  let vars = {};

  href.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
    (m, key, value) => {
      vars[key] = value;
    }
  );

  return vars;
};

export default getUrlParams;
