import Handsontable from 'handsontable';
import * as DataTableHelper from '@/lib/dataTableHelpers.js';
import { MultiSelectEditor, MultiSelectRenderer } from '@/lib/handsontable/editors/multiselect';
import { ClientDataMapper } from '@/lib/data_mappers/client/client.js';
import { apolloClient } from '@/lib/apollo';
import client_phones from '@/lib/handsontable/renderer/client_phones.js';
import store from '@/store';

export const clientFields = [
  {
    value: 'clientPhones',
    parentFieldName: 'client',
    title: 'Телефон',
    width: 120,
    columnGroup: 'Клиент',
    isHiddenOnForm: true,
    renderer: client_phones,
    readOnly: true
  },
  {
    value: 'clientPhones',
    parentFieldName: 'client',
    title: 'Телефон',
    width: 120,
    columnGroup: 'Клиент',
    hidden: true,
    children: [
      {
        value: 'canSendSms',
        title: 'Может отправлять СМС',
        type: 'checkbox',
        readOnly: false
      },
      {
        value: 'phone',
        title: 'Телефон',
        readOnly: false,
        formComponent: 'client_phones'
      },
      {
        value: 'comment',
        title: 'Комментарий',
        formComponent: 'textarea',
        readOnly: false
      }
    ]
  },
  {
    value: 'clientName',
    parentFieldName: 'client',
    title: 'Имя клиента',
    width: 130,
    columnGroup: 'Клиент',
    isHiddenOnForm: true,
    hidden: true
  },
  {
    value: 'clientTags',
    parentFieldName: 'client',
    title: 'Теги',
    width: 250,
    columnGroup: 'Клиент',
    formComponent: 'multiselect',
    readOnly: false,
    renderer: MultiSelectRenderer,
    editor: MultiSelectEditor,
    select: {
      config: {
        separator: ', ',
        valueKey: 'id',
        labelKey: 'name'
      },
      options: (source, process) => {
        const client = new ClientDataMapper(apolloClient);
        store.commit('clients/createClientInstance', client);
        const res = client.getClientTags({ all: true, active: true }).then((res) => res);
        return res;
      },
    }
  },
  {
    value: 'clientComment',
    parentFieldName: 'client',
    title: 'Комментарий к клиенту',
    width: 200,
    columnGroup: 'Клиент',
    formComponent: 'textarea',
    hasToken: true
  },
]