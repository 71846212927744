const investmentObjectFields = {
  namespaced: true,
  state: {
    taskTypes: [],
    checkFields: [],
    form: {
      changedFields: []
    }
  },

  mutations: {
    setTaskTypes(state, taskTypes) {
      state.taskTypes = taskTypes;
    },
    setCheckFields(state, checkFields) {
      state.checkFields = checkFields;
    },
    addChangedFields(state, changedFields) {
      const hasIndex = state.form.changedFields.some(el => el.field == changedFields[0].field);
      if (hasIndex) {
        state.form.changedFields = state.form.changedFields.filter(el => el.field != changedFields[0].field);
      }
      state.form.changedFields = [...state.form.changedFields, ...changedFields];
    },
    setChangedFields(state, changedFields) {
      state.form.changedFields = changedFields;
    }
  },

  getters: {
    getTaskTypes(state) {
      return state.taskTypes;
    },
    getCheckFields(state) {
      return state.checkFields;
    },
    getChangedFields(state) {
      return state.form.changedFields
    }
  },
};

export default investmentObjectFields;