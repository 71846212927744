import * as apolloQueries from './user_queries.js';
import { apolloClient } from '@/lib/apollo';

export class UserDataMapper {
  #apolloClient = null;
  #items = [];

  constructor(apolloClient) {
    this.#apolloClient = apolloClient;
  }

  async create(args) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_create,
      variables: {
        email: args.email,
        firstName: args.firstName,
        lastName: args.lastName,
        password: args.password,
        position: args.position,
        roles: args.roles,
        countObjectsAssignedInDay: args.settingCountAssignObjectsInDay,
        countObjectsInWork: args.settingCountObjectsInWork,
        contactTelegram: args.contactTelegram
      },
    })
    .then((data, loading) => {
      if (!loading) {
        // this.buildModule(data);
        return data;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }

  async update(args) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_update,
      variables: {
        id: args.id,
        firstName: args.firstName,
        lastName: args.lastName,
        password: args.password,
        position: args.position,
        roles: args.roles,
        countObjectsAssignedInDay: args.settingCountAssignObjectsInDay,
        countObjectsInWork: args.settingCountObjectsInWork,
        contactTelegram: args.contactTelegram
      },
    })
    .then((data, loading) => {
      if (!loading) {
        // this.buildModule(data);
        return data;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }

  buildModule(data) {
    // return Object.assign(new User(), data);
  }

  async getListUsers(args) {
    const hasQueries = args.hasQueries;
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.query_users(hasQueries),
      variables: {
        first: args.first,
        fields: args.fields,
        orders: args.orders,
        search: args.search
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.users.nodes;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }
}

const user = new UserDataMapper(apolloClient);

export default user;
  