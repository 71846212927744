import gql from 'graphql-tag';

export default gql`
  query investmentObjectClientPhones ($id: ID!) {
    investmentObject(id: $id) {
      client {
        phones(show: true) {
          phone
        }
      }
    }
  }
`;