<template>
  <div v-if="$route.meta.defaultLayout">
    <navbar v-if="!hasToken"></navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'Default',
  components: {
    Navbar
  },
  computed: {
    hasToken() {
      return this.$route.query.token;
    }
  }
};
</script>
