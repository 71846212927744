import Vue from 'vue';
import VueApollo from '@vue/apollo-option';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from "@apollo/client/link/error";
import { ApolloLink, concat } from 'apollo-link';
import auth from '@/lib/Auth.js';
import getUrlParams from '@/lib/getUrlParams';
import ActionCable from 'actioncable';
import { ActionCableLink } from 'graphql-ruby-client';
import errorHandler from '@/lib/ErrorHandler';
import { createUploadLink } from 'apollo-upload-client';

Vue.use(VueApollo);

const wsClient = ActionCable.createConsumer(process.env.VUE_APP_SHIVA_ACTIONCABLE_URL);

// HTTP connection to the API
const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_SHIVA_GRAPHQL_URL
});

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const newHeader = {};
  let token = null;
  if (getUrlParams().token) {
    token = getUrlParams().token.endsWith('%3D') ? getUrlParams().token.slice(0, -3) : getUrlParams().token;
  }

  newHeader['Access-Token'] = auth.accessToken;
  newHeader['client'] = auth.client;
  newHeader['uid'] = auth.uid;  
  newHeader['X-ENCRYPTED-TOKEN'] = token;
  newHeader['X-INTERNAL-ADMIN'] = true;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...newHeader
    }
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      errorHandler.handleError('[GraphQL error]', { message, locations, path })
    )
  }

  if (networkError) {
    errorHandler.handleError('[GraphQL Network error]', { networkError })
  }
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const wsLink = new ActionCableLink({ cable: wsClient });

const link = ApolloLink.split(
  hasSubscriptionOperation,
  // split based on operation type
  wsLink,
  uploadLink,
  errorLink
);

const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: concat(authLink, link),
  cache: cache
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    $mutate: {
      errorPolicy: 'all',
    },
  }
});
