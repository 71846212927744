import getUrlParams from '@/lib/getUrlParams';

class Auth {
  #credentials = null;
  #errorIsVisible = null;
  
  constructor(credentials) {
    this.#credentials = credentials;
  }
  
  get uid() {
    return this.credentials.uid;
  }

  get expiry() {
    return this.credentials.expiry; 
  }

  get accessToken() {
    return this.credentials.accessToken; 
  }

  get client() {
    return this.credentials.client; 
  }

  get token() {
    return this.credentials.token;
  }

  get isAuth() {
    return (this.uid && this.isNotExpired) || this.token;
  }

  get errorIsVisible() {
    return this.#errorIsVisible;
  }

  get credentials() {
    if (this.#credentials) {
      return this.#credentials;
    }
    else {
      if (getUrlParams().token) {
        this.clearCredentials();

        this.#credentials = {
          token: getUrlParams().token
        };
      } else if (localStorage.hasOwnProperty('authCredentials')) {
        this.#credentials = JSON.parse(localStorage.getItem('authCredentials'));
      } else {
        this.#credentials = {}
      }
    }
    return this.#credentials;
  }

  dumpCredentials() {
    localStorage.setItem('authCredentials', JSON.stringify(this.#credentials));
  }

  clearCredentials() {
    localStorage.removeItem('authCredentials');
    this.#credentials = null;
  }

  applyCredentials(credentials) {
    this.#credentials = credentials;
  }

  showAuthError() {
    this.#errorIsVisible = true;
  }
  
  get isNotExpired() {
    return new Date(this.expiry * 1000) > new Date();
  }
}

const auth = new Auth();

export default auth;
