<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="navbar-left">
      <a class="navbar-brand" href="#">Shiva AI</a>
      <div class="navbar-uid"
           v-on:click="openUserInfoAlert">{{ uid }}</div>
    </div>
    <button class="navbar-toggler"
            type="button"
            v-on:click="openNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div v-bind:class="navbarClass">
      <div class="mr-auto d-flex align-items-center">
        <ul class="navbar-nav">
          <li v-for="el in navItems"
              v-bind:key="el.name">
            <a v-on:click="routingTo(el.name, getRouteParams(el))"
               v-on:click.middle="openInNewTab(el.name, getRouteParams(el))"
               v-bind:class="navLinkClass(el.name)"
               v-if="navItemIsVisible(el)">{{ el.text }}</a>
          </li>
        </ul>
      </div>
      <span class="navbar-text">
        <polygon-select></polygon-select>
        <button class="btn btn-outline-primary ml-2"
                v-on:click.prevent="logout">Выйти</button>
      </span>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import PolygonSelect from '@/components/PolygonSelect.vue';
import auth from '@/lib/Auth.js';
import obscureEmail from '@/lib/obscureEmail.js';
import getBrowserDetails from '@/lib/browserDetails.js';
import appContext from '@/AppInit';
import * as constants from '@/assets/data/constants';
import moment from 'moment';

const momentFormat = 'DD.MM.YYYY HH:mm';

const NAV_ITEMS = [
  {
    name: 'PendingApartments',
    text: 'В работе'
  },
  {
    name: 'Visits',
    text: 'Просмотры',
    routeParams: {
      type: 'order',
      field: 'planViewingAt',
      direction: 'asc'
    }
  },
  {
    name: 'FavouriteApartments',
    text: 'Оценка',
  },
  {
    name: 'LegalChecks',
    text: 'Юрпроверки',
  },
  {
    name: 'Portfolio',
    text: 'Одобрено на сделку',
  },
  {
    name: 'AllApartments',
    text: 'Все квартиры',
  },
  {
    name: 'CalculationClaims',
    text: 'Заявки',
    canUserViewCalcClaims: true
  },
  {
    name: 'AlgorithmMistakes',
    text: 'Ошибки',
    showOnlyIfAdmin: true
  },
  {
    name: 'Statistics',
    text: 'Пульс',
    showOnlyIfAdmin: true
  },
  {
    name: 'Users',
    text: 'Пользователи',
    showOnlyIfAdmin: true
  },
  // {
  //   name: 'Clients',
  //   text: 'Клиенты',
  //   showOnlyIfAdmin: true
  // },
  {
    name: 'Partners',
    text: 'Партнеры',
    showOnlyIfAdmin: true
  }
]

export default {
  components: {
    PolygonSelect,
  },
  data() {
    return {
      navbarIsOpened: false,
      navItems: NAV_ITEMS
    };
  },
  computed: {
    userHasLimits() {
      return this.getBrokerAssignInfo.settingCountAssignObjectsInDay || this.getBrokerAssignInfo.settingCountObjectsInWork;
    },
    navbarClass() {
      return [
        'collapse navbar-collapse',
        {
          'show': this.navbarIsOpened
        }
      ]
    },
    ...mapGetters([
      'getPolygonId',
      'getGroupId',
      'isAdminRole',
      'canUserViewCalcClaims',
      'getCurrentRole',
      'getBrokerAssignInfo'
    ]),
    uid() {
      return obscureEmail(auth.uid);
    }
  },
  methods: {
    getRouteParams(page) {
      return page.routeParams || this.defaultRouteParams(page.name);
    },
    defaultRouteParams(page) {
      if (page == 'PendingApartments') {
        if (this.userHasLimits) {
          return { type: 'order', field: 'assignedUserAtInfo', direction: 'desc' };
        } else {
          return { type: 'order', field: 'workflowScopePendingFirstAtInfoAt', direction: 'desc' };
        }
      }

      if (this.userHasLimits) return { type: 'order', field: 'assignedUserAtInfo', direction: 'desc' };

      return { type: 'order', field: 'externalFirstCrawlerAt', direction: 'desc' }
    },
    getRoutingQuery(obj) {
      const query = Object.assign({}, this.$route.query, {
        polygon_id: this.getPolygonId || this.$route.query.polygon_id || undefined,
        group_id: this.getGroupId || this.$route.query.group_id || undefined
      });
      delete query.investment_object_data_id;
      delete query.tab;
      this.$store.commit('changeCountVisibleInvestmentObjects', +constants.COUNT_VISIBLE_OBJECTS);
      this.closeNavbar();
      if (obj) {
        if (obj.type == 'order') {
          query.order_name = obj.field;
          query.order_direction = obj.direction;
          delete query.order_by_calculation_signal_name;
          delete query.order_by_calculation_signal_direction;
        } else if (obj.type == 'order_by_calculation_signal') {
          query.order_by_calculation_signal_name = obj.field;
          query.order_by_calculation_signal_direction = obj.direction;
          delete query.order_name;
          delete query.order_direction;
        }

        delete query.investment_object_data_id;
      }

      return query;
    },
    routingTo(name, obj) {
      const q = this.getRoutingQuery(obj);

      this.$router.push({
        name,
        query: { ...q }
      });
    },
    openInNewTab(name, obj) {
      const q = this.getRoutingQuery(obj);

      let routeData = this.$router.resolve({ name, query: q });
      window.open(routeData.href, '_blank');
    },
    navLinkClass(name) {
      return [
        'nav-link',
        {
          'active': this.$route.name === name
        }
      ]
    },
    openNavbar() {
      this.navbarIsOpened = !this.navbarIsOpened;
    },
    closeNavbar() {
      this.navbarIsOpened = false;
    },
    logout() {
      auth.clearCredentials();

      appContext.init(() => {
        this.$router.push({
          name: 'Authorization'
        });
      });
    },
    openUserInfoAlert() {
      const browser = `${getBrowserDetails().browserName} ${getBrowserDetails().fullVersion}`;
      const copyText = `Email: ${auth.uid}\nСписок ролей: ${this.getCurrentRole.join(', ')}\nБраузер: ${browser}\nUserAgent: ${getBrowserDetails().userAgent}\nОС: ${getBrowserDetails().osName}\nUrl: ${window.location.href}\nЛимит объектов в день: ${this.getBrokerAssignInfo.settingCountAssignObjectsInDay}\nЛимит объектов в работе: ${this.getBrokerAssignInfo.settingCountObjectsInWork}\nДата и время: ${moment().format(momentFormat)}`;
      navigator.clipboard.writeText(copyText);
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert(copyText);
        })
        .catch(() => {
          alert('something went wrong');
        });
    },
    navItemIsVisible(el) {
      if (el.showOnlyIfAdmin) return this.isAdminRole;
      if (el.canUserViewCalcClaims) return this.canUserViewCalcClaims;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  box-shadow: 0 0 .8rem rgba(#000, .15);
  font-size: 1.4rem;
  height: 5.1rem;
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;

  a {cursor: pointer;}
}
.nav-link {
  border: 2px solid transparent;
  padding: $navbar-padding-y;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;

  &.router-link-exact-active,
  &.active,
  &:hover {
    border-bottom-color: $primary;
  }
}

.navbar-brand {
  font-weight: 700;
}

.navbar-text {
  display: inline-flex;
  align-items: center;
}

.navbar-left {
  padding-right: .5rem;
}

.navbar-uid {
  font-size: 1rem;
  margin-top: -6px;
}

@media only screen and (max-width: 1320px) {
  .navbar {
    font-size: 1.3rem;
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    position: relative;
    height: auto;
  }

  .navbar-brand {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .navbar-collapse {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .nav-item {
    margin-bottom: 1rem;
  }

  .nav-link {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 0.5rem;
    padding-top: 0;
  }

  .navbar-text {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
