export default {
  methods: {
    updateParam(obj) {
      let query = Object.assign({}, this.$route.query);

      if (obj.lsKey && obj.lsKey == 'polygon_id' || obj.lsKey == 'group_id') {
        if (obj.v == null) {
          localStorage.removeItem(obj.lsKey)
        } else {
          localStorage.setItem(obj.lsKey, obj.v);
        }
      }

      if (obj.storeKey) {
        if (obj.v) {
          if (obj.v == '') {
            this.$store.commit(obj.storeKey, null);
          } else {
            this.$store.commit(obj.storeKey, obj.v);
          }
        } else if (obj.values) {
          this.$store.commit(obj.storeKey, obj.values);

          obj.values.forEach(el => {
            if (Object.keys(el).length != 0 && el != null) {
              if ('name' in el && 'condition' in el) {
                query[el.name] = `${el.condition}_${el.value}`;
        
                if (el.condition == 'notBetween' || el.condition == 'between') {
                  query[el.name] = `${el.condition}_${el.value}_${el.value2}`;
                }
              }
            }
          })
        } else {
          this.$store.commit(obj.storeKey);
        }
      }

      if (!obj.delete && !obj.deleteAll) {
        if (typeof obj.v == 'object' && Object.keys(obj.v).length != 0 && obj.v != null) {
          if ('name' in obj.v && 'condition' in obj.v) {
            query[obj.v.name] = `${obj.v.condition}_${obj.v.value}`;
    
            if (obj.v.condition == 'notBetween' || obj.v.condition == 'between') {
              query[obj.v.name] = `${obj.v.condition}_${obj.v.value}_${obj.v.value2}`;
            }
          } else if ('field' in obj.v && 'direction' in obj.v) {
            query[`${obj.type}_name`] = obj.v.field;
            query[`${obj.type}_direction`] = obj.v.direction;
          }
        } else {
          query[obj.lsKey] = obj.v == '' || obj.v == null ? undefined : obj.v;
        }
      }

      if (obj.delete) {
        delete query[obj.fieldName];
      }

      if (obj.deleteAll) {
        obj.fields.forEach(el => {
          delete query[el.name];
        });
      }

      this.$router.replace({ path: this.$route.path, query: { ...query } }).catch(()=>{});
    }
  }
}