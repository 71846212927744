const trimString = (value, countSymbols) => {
  if (!value) return '';
  let sliced = value.slice(0, countSymbols);

  if (sliced.length < value.length) {
    sliced += '...';
  }

  return sliced;
}

export default trimString;
