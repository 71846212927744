import Handsontable from 'handsontable';
import { formatNumber } from './../../lib/formatNumber.js';
import * as DataTableHelper from './../../lib/dataTableHelpers.js';
import DateTimeEditor from './../../lib/handsontable/editors/datetimepicker.js';
import validators from '../../lib/handsontable/validators.js';
import moment from 'moment';
import temporaryPublicUrl from './../../lib/handsontable/renderer/temporaryPublicUrl.js';
import phones from '@/lib/handsontable/renderer/phones.js';
import trimString from '@/lib/handsontable/trimString.js';
import obscureEmail from '@/lib/obscureEmail.js';
import obscureTelegramUserName from '@/lib/obscureTelegramUserName.js';
import * as constants from '@/assets/data/constants';
import store from '@/store';
import { clientFields } from '@/lib/data_mappers/client/fields.js';
const materialTypeArr = DataTableHelper.formatSelect(constants.materialTypeObj);
const repairTypeArr = DataTableHelper.formatSelect(constants.repairTypeObj);
const ratingListArr = DataTableHelper.formatSelect(constants.ratingListObj);

const convertMonthsToSeconds = (m) => m * 31 * 24 * 60 * 60;

const mathConditions = [
  {
    title: 'equal',
    shortTitle: 'eq',
    symbol: ' == '
  },
  {
    title: 'notEqual',
    shortTitle: 'neq',
    symbol: '!='
  },
  {
    title: 'blank',
    shortTitle: 'empty',
    symbol: '∅'
  },
  {
    title: 'present',
    shortTitle: 'not_empty',
    symbol: '!∅'
  },
  {
    title: 'greater',
    shortTitle: 'gt',
    symbol: '>'
  },
  {
    title: 'greaterOrEqual',
    shortTitle: 'gte',
    symbol: '≥'
  },
  {
    title: 'less',
    shortTitle: 'lt',
    symbol: '<'
  },
  {
    title: 'lessOrEqual',
    shortTitle: 'lte',
    symbol: '≤'
  },
  {
    title: 'between',
    shortTitle: 'between',
    symbol: 'между'
  },
  {
    title: 'notBetween',
    shortTitle: 'not_between',
    symbol: 'не между'
  },
  {
    title: 'startWith',
    shortTitle: 'begins_with',
    symbol: 'начинается с'
  },
  {
    title: 'endWith',
    shortTitle: 'ends_with',
    symbol: 'заканчивается на'
  },
  {
    title: 'include',
    shortTitle: 'contains',
    symbol: 'содержит'
  },
  {
    title: 'notInclude',
    shortTitle: 'not_contains',
    symbol: 'не содержит'
  },
];

const momentFormat = 'DD.MM.YYYY HH:mm';

class GridConstants {
  #favourite = null;

  get mathConditions() {
    return mathConditions;
  }

  get unitShort() {
    return [
      { value: 'month', text: 'мес.' }
    ];
  }

  get momentFormat() {
    return momentFormat;
  }

  get magicUrl() {
    return `${process.env.VUE_APP_DOMAIN}#/`;
  }

  get ratingByExpertMinValue() {
    return 50000;
  }

  get secureTokenExpiresIn() {
    return convertMonthsToSeconds(2);
  }

  currentTasks() {
    const arr = store.getters['investmentObjectFields/getTaskTypes'];
    const emptyObj = { value: '', text: 'Ничего не выбрано' };
    const arrOfObj = Array.from(arr, x => {
      return { value: x.codeGraqphql, text: x.localeRu };
    });

    return {
      arr: ['Ничего не выбрано', ...Array.from(arr, x => x.localeRu)],
      arrOfObj: [emptyObj, ...arrOfObj]
    };
  }

  checkFields(mutationName) {
    const arr = store.getters['investmentObjectFields/getCheckFields'].filter(el => el.mutationName == mutationName)[0].values;
    const arrOfObj = Array.from(arr, x => {
      return { value: x.name, text: x.label };
    });

    return {
      arr: [...Array.from(arr, x => x.label)],
      arrOfObj: [...arrOfObj]
    };
  }

  buildFavourite() {
    return [
      {
        value: 'controlPanel',
        title: 'Панель управления',
        width: 235
      },
      {
        value: 'publicUrl',
        title: 'RealisteId',
        type: 'numeric',
        width: 90,
        hidden: true
      },
      {
        value: 'id',
        title: 'Magic (ID)',
        columnGroup: 'Объект',
        type: 'numeric',
        width: 80,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          if (!value) {
            td.innerHTML = '';
            return;
          }
          td.innerHTML = value && value.url ? `<div class="text-truncate"><a href="${value.url}" rel="noreferrer" target="_blank" class="text-nowrap">${value.id}</a></div>`: `<span>${value.id}</span>`;
        },
        section: 'head'
      },
      {
        value: 'commentChecksForPublic',
        title: 'Проверки объекта',
        columnGroup: 'Объект',
        width: 400,
        formComponent: 'textarea',
        hidden: true
      },
      {
        value: 'externalFirstCrawlerAt',
        title: 'Добавлено в базу',
        columnGroup: 'Объект',
        type: 'numeric',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
      },
      {
        value: 'workflowScopePendingFirstAtInfoAt',
        parentFieldName: 'workflowScopePendingFirstAtInfo',
        title: 'Впервые в работе с',
        columnGroup: 'Объект',
        type: 'numeric',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
        section: 'head',
      },
      {
        value: 'calcClaimSource',
        title: 'Цель',
        columnGroup: 'Объект',
        width: 120
      },
      {
        value: 'calcClaimUtmParams',
        title: 'UTM метки',
        columnGroup: 'Объект',
        width: 200,
        formComponent: 'pre',
        customRender: (val) => {
          if (!val || Object.keys(val).length === 0) return;
          let str = '';
          for (const [key, value] of Object.entries(val)) {
            let v = value.length > 0 ? value.join(', ') : value;
            str += `${key}: ${v}\n`;
          }
          return `<pre>${str}</pre>`;
        },
      },
      {
        value: 'commentFromCalcClaim',
        title: 'Комментарий',
        columnGroup: 'Объект',
        width: 200,
        formComponent: 'pre',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          if (!value) return;
          const arr = value.split('\n');
          if (arr.length < 3) {
            td.innerHTML = value;
            return;
          }
          const href = arr[2].split(': ')[1];
          td.innerHTML = arr.join('\n').replace(href, `<a href="${href}" target="_blank">${href}</a>`);
        },
        customRender: (val) => {
          return `<pre>${val}</pre>`;
        },
      },
      {
        value: 'assignedUserEmail',
        filterName: 'assignedUser',
        filterTitle: 'Назначенный пользователь',
        parentFieldName: 'assignedUser',
        title: 'Почта',
        width: 100,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          if (!value) {
            td.innerHTML = '';
            return;
          }
          td.innerHTML = obscureEmail(value);
        },
        columnGroup: 'Брокер',
        readOnly: DataTableHelper.setFieldReadOnly(['admin', 'callCenter', 'valuer', 'lawyer', 'manager']),
      },
      {
        value: 'assignedUserContactInfoTelegram',
        filterName: 'assignedUser',
        filterTitle: 'Назначенный пользователь',
        parentFieldName: 'assignedUser.contactInfo',
        title: 'Телеграм',
        width: 140,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          if (!value) {
            td.innerHTML = '';
            return;
          }
          td.innerHTML = obscureTelegramUserName(value);
        },
        columnGroup: 'Брокер',
        readOnly: DataTableHelper.setFieldReadOnly(['admin', 'callCenter', 'valuer', 'lawyer', 'manager']),
      },
      {
        value: 'assignedUserAtInfoAt',
        parentFieldName: 'assignedUserAtInfo',
        orderName: 'assignedUserAtInfo',
        title: 'Когда назначен',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? DataTableHelper.getLocalTime(value) : null;
        },
        columnGroup: 'Брокер',
        formComponent: 'datetime',
      },
      {
        value: 'currentTask',
        title: 'Задача',
        width: 160,
        type: 'dropdown',
        source: this.currentTasks().arr,
        sourceObj: this.currentTasks().arrOfObj,
        section: 'head',
      },
      {
        value: 'calculationCheckUrl',
        title: 'Ссылка на конкурентов',
        width: 160,
        section: 'head',
        hidden: true,
        isHiddenOnForm: DataTableHelper.checkHasRole('user')
      },
      {
        value: 'priceMarket',
        title: 'Рыночная цена, 3&nbsp;мес. (алгоритм)',
        columnGroup: 'Алгоритм',
        width: 130,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        hidden: DataTableHelper.checkHasRole('user'),
        isHiddenOnForm: DataTableHelper.checkHasRole('user')
      },
      {
        value: 'priceShownObviousAd',
        title: 'Рыночная цена, 12&nbsp;мес. (алгоритм)',
        columnGroup: 'Алгоритм',
        width: 140,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        isHiddenOnForm: DataTableHelper.checkHasRole('user')
      },
      {
        value: 'pricePurchase',
        title: 'Цена выкупа (оценка системы)',
        columnGroup: 'Алгоритм',
        width: 130,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = `${formatNumber(value)} ₽`
        },
        code: 'RUB',
        isHiddenOnForm: DataTableHelper.checkHasRole('user')
      },
      {
        value: 'tags',
        title: 'Теги',
        columnGroup: 'Алгоритм',
        width: 130,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          if(!value || value.length == 0) {
            td.innerHTML = '';
            return;
          }

          td.innerHTML = value.join(', ');
          td.classList.add('word-wrap-break-word')
        },
        formComponent: 'pre',
        customRender: (val) => {
          if (!val) return;
          return val ? val.join(', ') : null;
        }
      },
      {
        value: 'ratingByExpert',
        title: 'Рын. цена (оценка&nbsp;эксперта)',
        formTitle: 'Рын. цена по данным из объявления (оценка&nbsp;эксперта)',
        modal: {
          title: 'Рыночная цена по данным из объявления (оценка&nbsp;эксперта)',
          description: `<p>Вносим ТОЛЬКО цену, которую вы считаете рыночной по информации из объявления! (Если в тексте объявления написано, что доля, а в объявлении целая квартира - ваша оценка ставится исходя из параметров объявления только)</p>
<p>Если оказывается, что там доля при звонке или перепланировка или другие факторы диссонирующие - пишется об этом в комментарии оценщика или менеджера, но в поле выше ставится ТОЛЬКО рыночная цена Объявления (не квартиры)</p>
<p>Машина не видит проблем, которые вскрываются из-за документов или вранья в объявлении. Вы ставите цены часто с учетом уже этих факторов - машина сбивается.</p>`
        },
        width: 150,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        section: 'head',
        validator: 'rating-by-expert',
        allowInvalid: false,
        columnGroup: 'Оценка эксперта'
      },
      {
        value: 'priceByValuer',
        title: 'Цена выкупа (цена эксперта)',
        modal: {
          title: 'Цена выкупа (цена эксперта)',
          description: `<p>В поле выкупа ставим цену УЖЕ со всеми известными нам факторами по квартире (включая если там труп лежит в квартире) и с учетом дисконта в 6% от рыночной цены продажи этой квартиры с учетом всех известных нам факторов.</p>`
        },
        width: 120,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `${formatNumber(value)} ₽` : '';
        },
        section: 'head'
      },
      {
        value: 'check__legalChecks__checkOwnersCount',
        title: 'Кол-во собственников',
        width: 140,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__legalChecks__checkOwnersCount').arr,
        sourceObj: this.checkFields('check__legalChecks__checkOwnersCount').arrOfObj,
      },
      {
        value: 'flatInfoFloorNumber',
        parentFieldName: 'flatInfo',
        title: 'Этаж',
        type: 'numeric',
        width: 100,
        columnGroup: 'Колл-центр',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        }
      },
      {
        value: 'flatInfoArea',
        parentFieldName: 'flatInfo',
        title: 'Общая пл., м2',
        type: 'numeric',
        width: 100,
        columnGroup: 'Колл-центр',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        }
      },
      {
        value: 'flatInfoAreaLiving',
        parentFieldName: 'flatInfo',
        title: 'Жилая площадь, м2',
        type: 'numeric',
        width: 110,
        columnGroup: 'Колл-центр',
      },
      {
        value: 'flatInfoAreaKitchen',
        parentFieldName: 'flatInfo',
        title: 'Площадь кухни, м2',
        type: 'numeric',
        width: 100,
        columnGroup: 'Колл-центр'
      },
      {
        value: 'check__legalChecks__checkEncumbrances',
        title: 'Есть обременения?',
        width: 120,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__legalChecks__checkEncumbrances').arr,
        sourceObj: this.checkFields('check__legalChecks__checkEncumbrances').arrOfObj,
      },
      {
        value: 'check__documentsPropertyTitle__durationSinceLastCheck',
        title: 'Месяцев в собственности',
        width: 160,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__documentsPropertyTitle__durationSinceLastCheck').arr,
        sourceObj: this.checkFields('check__documentsPropertyTitle__durationSinceLastCheck').arrOfObj
      },
      {
        value: 'check__views__checkViewRoadNoiseWhenWindowOpen',
        title: 'При открытом окне шум от дороги',
        width: 160,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__views__checkViewRoadNoiseWhenWindowOpen').arr,
        sourceObj: this.checkFields('check__views__checkViewRoadNoiseWhenWindowOpen').arrOfObj
      },
      {
        value: 'check__views__checkViewBadViewFromWindow',
        title: 'Вид из окна на ЖД пути, пром зону и т.п.',
        width: 160,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__views__checkViewBadViewFromWindow').arr,
        sourceObj: this.checkFields('check__views__checkViewBadViewFromWindow').arrOfObj
      },
      {
        value: 'check__views__checkWoodenFloorsBetweenFloors',
        title: 'Деревянные перекрытия',
        width: 160,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__views__checkWoodenFloorsBetweenFloors').arr,
        sourceObj: this.checkFields('check__views__checkWoodenFloorsBetweenFloors').arrOfObj
      },
      {
        value: 'check__views__checkViewAllRoomsHaveWindow',
        title: 'Есть комната без окна',
        width: 120,
        columnGroup: 'Колл-центр',
        type: 'dropdown',
        source: this.checkFields('check__views__checkViewAllRoomsHaveWindow').arr,
        sourceObj: this.checkFields('check__views__checkViewAllRoomsHaveWindow').arrOfObj
      },  
      {
        value: 'countComment',
        title: 'Кол-во комментариев',
        width: 120,
        columnGroup: 'Комментарии',
        isHiddenOnForm: true
      },
      {
        value: 'comment',
        title: 'Комментарий брокера',
        width: 400,
        columnGroup: 'Комментарии',
        formComponent: 'textarea',
        readOnly: DataTableHelper.setFieldReadOnly(['user', 'internal']),
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = trimString(value, 200);
        },
      },
      {
        value: 'commentAtInfoAt',
        parentFieldName: 'commentAtInfo',
        title: 'Брокер прокомментировал',
        width: 120,
        columnGroup: 'Комментарии',
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
      },
      {
        value: 'commentMainManager',
        title: 'Комментарий коллцентра',
        width: 400,
        columnGroup: 'Комментарии',
        formComponent: 'textarea',
        readOnly: DataTableHelper.setFieldReadOnly('callCenter'),
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = trimString(value, 200);
        },
      },
      {
        value: 'commentMainManagerAtInfoAt',
        parentFieldName: 'commentMainManagerAtInfo',
        title: 'Коллцентр прокомментировал',
        width: 120,
        columnGroup: 'Комментарии',
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
      },
      {
        value: 'commentValuer',
        title: 'Комментарий оценщика',
        width: 400,
        columnGroup: 'Комментарии',
        formComponent: 'textarea',
        readOnly: DataTableHelper.setFieldReadOnly('valuer'),
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = trimString(value, 200);
        },
      },
      {
        value: 'commentValuerAtInfoAt',
        parentFieldName: 'commentValuerAtInfo',
        title: 'Оценщик прокомментировал',
        width: 120,
        columnGroup: 'Комментарии',
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
      },
      {
        value: 'legalCheckReport',
        title: 'Комментарий юриста',
        width: 400,
        columnGroup: 'Комментарии',
        formComponent: 'textarea',
        readOnly: DataTableHelper.setFieldReadOnly('lawyer'),
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = trimString(value, 200);
        },
      },
      {
        value: 'legalCheckReportAt',
        title: 'Дата и время юр.проверки',
        width: 120,
        columnGroup: 'Комментарии',
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
      },
      {
        value: 'docsUrl',
        title: 'Ссылки на документы',
        width: 120,
        columnGroup: 'Комментарии',
        formComponent: 'textarea_with_hyperlinks',
        hidden: true,
        note: 'Каждую ссылку необходимо вводить с новой строки в формате "Описание ссылки https://сама-ссылка.рф"'
      },
      {
        value: 'commentAdmin',
        title: 'Комментарий менеджера',
        width: 400,
        columnGroup: 'Комментарии',
        formComponent: 'textarea',
        readOnly: DataTableHelper.setFieldReadOnly('manager'),
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = trimString(value, 200);
        },
      },
      {
        value: 'commentAdminAtInfoAt',
        parentFieldName: 'commentAdminAtInfo',
        title: 'Менеджер прокомментировал',
        width: 120,
        columnGroup: 'Комментарии',
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
      },
      {
        value: 'commentDevelopersOnAlgorithmMistake',
        title: 'Комментарий разработчика',
        width: 400,
        columnGroup: 'Комментарии',
        formComponent: 'textarea',
        readOnly: DataTableHelper.setFieldReadOnly('admin'),
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = trimString(value, 200);
        },
        hidden: true
      },
      {
        value: 'commentDevelopersOnAlgorithmMistakeAtInfoAt',
        parentFieldName: 'commentDevelopersOnAlgorithmMistakeAtInfo',
        title: 'Разработчик прокомментировал',
        width: 120,
        columnGroup: 'Комментарии',
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
        hidden: true
      },
      {
        value: 'externalUrl',
        title: 'Ссылка на объявление',
        width: 100,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `<a href="${value}" rel="noreferrer" target="_blank">Ссылка</a>` : '';
        },
        section: 'head',
        customRender: (val) => {
          return val ? `<a href="${val}" rel="noreferrer" target="_blank">Ссылка</a>` : null;
        }
      },
      {
        value: 'price',
        title: 'Цена объявления',
        width: 130,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        section: 'head'
      },
      {
        value: 'pricePerArea',
        title: 'Цена за кв. м.',
        width: 110,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        section: 'head',
        hidden: true
      },
      {
        value: 'discountFromPriceMarket',
        title: 'Дисконт от рын. цены',
        type: 'numeric',
        width: 100,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `${value.toFixed(2)} %` : '';
        },
        section: 'head',
        code: '%',
        hidden: DataTableHelper.checkHasRole('user'),
        isHiddenOnForm: DataTableHelper.checkHasRole('user')
      },
      {
        value: 'published',
        title: 'Опубли&shy;ковано',
        type: 'checkbox',
        width: 80,
        section: 'head'
      },
      {
        value: 'phones',
        title: 'Телефоны объявления',
        width: 110,
        renderer: phones,
        section: 'head',
        formComponent: 'claim_phones',
        readOnly: true
      },
      ...clientFields,
      {
        value: 'margin',
        title: 'Маржа',
        type: 'numeric',
        width: 100,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${value.toFixed(2)} %` : '';
        },
        columnGroup: 'Как есть',
        code: '%',
        hidden: true
      },
      {
        value: 'priceMarketPerArea',
        title: 'Цена за кв. м.',
        width: 100,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        columnGroup: 'Как есть',
        isHiddenOnForm: true,
        hidden: true
      },
      {
        value: 'costUpgradesAndAdvForSaleAmount',
        title: 'Затраты на подготовку',
        width: 100,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        columnGroup: 'Как есть',
        hidden: true
      },
      {
        value: 'potentialPricesPriceMarket',
        parentFieldName: 'potentialPrices',
        title: 'Потенциальная цена',
        width: 110,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        section: 'head',
        code: 'RUB',
        hidden: true
      },
      {
        value: 'potentialPricesMargin',
        parentFieldName: 'potentialPrices',
        title: 'Маржа',
        width: 110,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `${(value * 100).toFixed(2)} %` : '';
        },
        code: 'RUB',
        columnGroup: 'Евроремонт',
        hidden: true
      },
      {
        value: 'potentialPricesPriceMarketPerArea',
        parentFieldName: 'potentialPrices',
        title: 'Цена за кв. м.',
        width: 110,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        columnGroup: 'Евроремонт',
        hidden: true
      },
      {
        value: 'potentialPricesCostUpgradesAndAdvForSaleAmount',
        parentFieldName: 'potentialPrices',
        title: 'Затраты на подготовку',
        width: 110,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        code: 'RUB',
        columnGroup: 'Евроремонт',
        hidden: true
      },      
      {
        value: 'projectProfitYearRate',
        title: 'Годовая доходность',
        type: 'numeric',
        width: 100,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `${value} %` : '';
        },
        code: '%',
        columnGroup: 'Экономика',
        hidden: true
      },
      {
        value: 'address',
        title: 'Адрес квартиры на продажу',
        width: 200,
        columnGroup: 'Описание квартиры',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          const o = JSON.parse(value);
          td.innerHTML = `${o.address}, <a href="http://maps.google.com/maps?q=&layer=c&cbll=${o.coordinate.lat},${o.coordinate.lng}" target="_blank" rel="noreferer" title="google panorama">gp</a>, <a href="https://yandex.ru/maps/?l=stv%2Csta&ll=<lng>,<lat>&panorama%5Bdirection%5D=339.757856%2C0.000000&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=${o.coordinate.lng},${o.coordinate.lat}&z=13.6" target="_blank" rel="noreferer" title="yandex panorama">yp</a>, <a href=" https://yandex.ru/maps/?whatshere[point]=${o.coordinate.lng},${o.coordinate.lat}&whatshere[zoom]=17" target="_blank" rel="noreferer" title="yandex card">yc</a>, <a href="https://2gis.ru/search/${o.coordinate.lat},${o.coordinate.lng}" target="_blank" rel="noreferer" title="2gis card">2c</a>, <a href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D1%81%D0%BF%D0%B8%D1%81%D0%BE%D0%BA-%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D0%BE%D0%B2/%D0%BA%D0%B0%D1%80%D1%82%D0%B0?search=${o.address}" target="_blank" rel="noreferer" title="наш.дом.рф">нд</a>`;
        },
        formComponent: 'textarea',
      },
      {
        value: 'buildingInfoConstructionYear',
        parentFieldName: 'buildingInfo',
        title: 'Год постройки',
        width: 100,
        type: 'numeric',
        columnGroup: 'Описание квартиры'
      },
      {
        value: 'buildingInfoRating',
        parentFieldName: 'buildingInfo',
        title: 'Рейтинг дома',
        type: 'dropdown',
        width: 80,
        source: ratingListArr,
        sourceObj: constants.ratingListObj,
        columnGroup: 'Описание квартиры',
        hasToken: true
      },
      {
        value: 'buildingInfoRatingComment',
        parentFieldName: 'buildingInfo',
        title: 'Комментарий к рейтингу',
        width: 150,
        columnGroup: 'Описание квартиры',
        hasToken: true
      },
      {
        value: 'flatInfoKadNumber',
        parentFieldName: 'flatInfo',
        title: 'Кадастровый номер',
        width: 140,
        columnGroup: 'Описание квартиры',
      },
      {
        value: 'walkTimeToMetro',
        title: 'Пешком до метро, мин.',
        type: 'numeric',
        width: 100,
        new: true,
        columnGroup: 'Описание квартиры',
        hidden: true
      },
      {
        value: 'jkInfoDisplayName',
        parentFieldName: 'jkInfo',
        title: 'ЖК',
        columnGroup: 'Описание квартиры'
      },
      {
        value: 'externalDescription',
        title: 'Описание объявления',
        columnGroup: 'Описание квартиры',
        formComponent: 'pre',
        customRender: (val) => {
          return val ? `<pre>${val}</pre>` : null;
        },
        hidden: true
      },
      {
        value: 'buildingInfoNewFinishDateYear',
        parentFieldName: 'buildingInfo',
        title: 'Сдача объекта: год',
        type: 'numeric',
        width: 130,
        columnGroup: 'Описание квартиры'
      },
      {
        value: 'buildingInfoMaterialType',
        parentFieldName: 'buildingInfo',
        title: 'Тип дома',
        type: 'dropdown',
        source: materialTypeArr,
        sourceObj: constants.materialTypeObj,
        width: 120,
        columnGroup: 'Описание квартиры'
      },
      {
        value: 'flatInfoRepairType',
        parentFieldName: 'flatInfo',
        title: 'Состояние ремонта',
        type: 'dropdown',
        width: 160,
        source: repairTypeArr,
        sourceObj: constants.repairTypeObj,
        columnGroup: 'Описание квартиры'
      },
      {
        value: 'flatInfoIsApartments',
        parentFieldName: 'flatInfo',
        title: 'Апартаменты?',
        type: 'checkbox',
        width: 120,
        columnGroup: 'Описание квартиры'
      },
      {
        value: 'flatInfoFloorNumber',
        parentFieldName: 'flatInfo',
        title: 'Этаж',
        type: 'numeric',
        width: 100,
        columnGroup: 'Описание квартиры',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        }
      },
      {
        value: 'buildingInfoFloorsCount',
        parentFieldName: 'buildingInfo',
        title: 'Этажность',
        type: 'numeric',
        width: 100,
        columnGroup: 'Описание квартиры',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        }
      },
      {
        value: 'flatInfoRoomsCount',
        parentFieldName: 'flatInfo',
        title: 'Кол-во комнат',
        type: 'numeric',
        width: 100,
        columnGroup: 'Описание квартиры',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        }
      },
      {
        value: 'flatInfoArea',
        parentFieldName: 'flatInfo',
        title: 'Общая пл., м2',
        type: 'numeric',
        width: 100,
        columnGroup: 'Описание квартиры',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        }
      },
      {
        value: 'flatInfoAreaLiving',
        parentFieldName: 'flatInfo',
        title: 'Жилая площадь, м2',
        type: 'numeric',
        width: 100,
        columnGroup: 'Описание квартиры',
        hidden: true
      },
      {
        value: 'flatInfoWindowViewType',
        parentFieldName: 'flatInfo',
        title: 'Вид из окон',
        width: 100,
        columnGroup: 'Описание квартиры',
        hidden: true
      },
      {
        value: 'flatInfoBalconiesCount',
        parentFieldName: 'flatInfo',
        title: 'Кол-во балконов',
        width: 100,
        columnGroup: 'Описание квартиры',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        },
        hidden: true
      },
      {
        value: 'flatInfoLoggiasCount',
        parentFieldName: 'flatInfo',
        title: 'Кол-во лоджий',
        width: 100,
        columnGroup: 'Описание квартиры',
        validations: {
          rules: ['required'],
          errorMessage: {
            required: 'Поле не может быть пустым'
          }
        },
        hidden: true
      },
      {
        value: 'viewScore',
        title: 'Просмотры',
        type: 'numeric',
        width: 110,
        columnGroup: 'Описание квартиры',
        hidden: true
      },
      {
        value: 'flatInfoNumber',
        parentFieldName: 'flatInfo',
        title: 'Номер квартиры',
        width: 120,
        columnGroup: 'Описание квартиры',
        hidden: true
      },
      {
        value: 'partnerName',
        parentFieldName: 'partner',
        title: 'Имя партнера',
        width: 120,
        section: 'head',
      },
      {
        value: 'calcClaimUrl',
        title: 'Ссылка перехода',
        width: 130,
        section: 'head',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value != null ? `<a href="${value}" target="_blank">Ссылка на форму заполнения заявки</a>` : '';
        },
        hidden: true
      },
      {
        value: 'expositionDays',
        parentFieldName: 'exposition',
        title: 'Дней на рынке',
        type: 'numeric',
        width: 80,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value || '';
        },
        section: 'head',
      },
      {
        value: 'editedAtInfoDays',
        parentFieldName: 'editedAtInfo',
        title: 'Дней с посл. редакции',
        type: 'numeric',
        width: 110,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value || '';
        },
        section: 'head',
      },
      {
        value: 'planViewingAt',
        title: 'Дата и время просмотра',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
        section: 'head',
        validator: 'plan-viewing-at',
      },
      {
        value: 'clientObjectFeedbackNeedAnswer',
        parentFieldName: 'clientObjectFeedback',
        title: 'Нужен ответ',
        type: 'checkbox',
        width: 70,
        isHiddenOnForm: true
      },
      {
        value: 'priceFactorChanges',
        title: 'Факторы, влияющие на цену',
        width: 150,
        columnGroup: 'Оценка эксперта',
        hidden: true
      },
      {
        value: 'priceSold',
        title: 'Продано за',
        width: 130,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value)} ₽` : '';
        },
        section: 'head',
        columnGroup: 'Продажа'
      },
      {
        value: 'priceSoldPerArea',
        title: 'Цена продажи за кв. м.',
        width: 130,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = value ? `${formatNumber(value.toFixed(2))} ₽` : '';
        },
        code: 'RUB',
        section: 'head',
        columnGroup: 'Продажа',
        hidden: true
      },
      {
        value: 'soldAt',
        title: 'Дата продажи',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
        section: 'head',
        columnGroup: 'Продажа',
        hidden: true
      },
      {
        value: 'duration',
        title: 'Длительность проекта',
        width: 130,
        columnGroup: 'Экономика',
        customRender: (val) => {
          if (!val) return;
          return `${val.duration} ${this.unitShort.filter(el => el.value == val.unit)[0].text}`;
        },
        hidden: true
      },
      {
        value: 'calculationSignals',
        title: 'Сигналы из расчета',
        width: 200,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          let arr = [];
          if (value) {
            for (const [k, v] of Object.entries(value)) {
              if (v) {
                typeof v === 'boolean' ? arr.push(k) : arr.push(`${k}(${v})`);
              }
            }
            td.innerHTML = arr.join(', ');
          }
        },
        columnGroup: 'Экономика',
        customRender: (val) => {
          let arr = [];
          if (val) {
            for (const [k, v] of Object.entries(val)) {
              if (v) {
                typeof v === 'boolean' ? arr.push(k) : arr.push(`${k}(${v})`);
              }
            }
          }
          const res = arr.join(', ');
          return `<pre>${res}</pre>`;
        },
        formComponent: 'pre',
        hidden: true
      },
      {
        value: 'calculationDate',
        title: 'Рассчитано',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        columnGroup: 'Экономика',
        formComponent: 'datetime',
        isHiddenOnForm: true,
        hidden: true
      },
      {
        value: 'publicUrlExpriresInThreeDays',
        title: 'Временная ссылка (3 дня)',
        width: 120,
        renderer: temporaryPublicUrl,
        columnGroup: 'Экономика',
        isHiddenOnForm: true,
        hidden: true
      },
      {
        value: 'viewingReport',
        title: 'Отчет осмотра',
        width: 170,
        formComponent: 'textarea',
        columnGroup: 'Просмотр',
        hidden: true
      },
      {
        value: 'viewingReportAtInfoAt',
        title: 'Дата и время изменения отчета осмотра',
        width: 120,
        columnGroup: 'Просмотр',
        hidden: true
      },
      {
        value: 'sellerInfoName',
        parentFieldName: 'sellerInfo',
        title: 'Продавец',
        width: 120,
        section: 'footer',
        hidden: true
      },
      {
        value: 'clientObjectFeedbackMessage',
        parentFieldName: 'clientObjectFeedback',
        title: 'Текст отзыва',
        width: 200,
        columnGroup: 'Отзыв',
        hidden: true
      },
      {
        value: 'clientObjectFeedbackPhone',
        parentFieldName: 'clientObjectFeedback',
        title: 'Телефон',
        width: 120,
        columnGroup: 'Отзыв',
        hidden: true
      },
      {
        value: 'clientObjectFeedbackCreatedAt',
        parentFieldName: 'clientObjectFeedback',
        title: 'Дата',
        width: 120,
        editor: DateTimeEditor,
        customType: 'dateTimeCustom',
        localTime: false,
        renderer: function(instance, td, row, col, prop, value) {
          Handsontable.renderers.HtmlRenderer.apply(this, arguments);
          td.innerHTML = DataTableHelper.getLocalTime(value);
        },
        formComponent: 'datetime',
        columnGroup: 'Отзыв',
        hidden: true
      },
    ]
  }

  get favouriteApartmentsGrid() {
    if (this.#favourite) return this.#favourite;

    this.#favourite = this.buildFavourite();

    return this.#favourite;
  }
}

const grid_constants = new GridConstants();

export default grid_constants;
