import { apolloClient } from './../../apollo';
import * as internalAdmin from '@/models/internalAdmin';

const expiresIn = 3 * 24 * 60 * 60; // 3 days

async function loadPublicUrl(id) {
  const x = await apolloClient.query({
    query: internalAdmin.publicUrl,
    variables: {
      investmentObjectId: id,
      expiresIn
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.publicUrl;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

const temporaryPublicUrl = (handsontableInstance, cell, row, col, prop, value, cellProperties) => {
  const objId = handsontableInstance.getCell(row, 1).textContent;
  const link = document.createElement('a');
  link.className = 'link-style';
  link.innerHTML = 'Ссылка';
  if (!cell.querySelector('.link-style')) {
    cell.innerHTML = '';
    cell.append(link);
  }
  link.addEventListener('click', (e) => {
    loadPublicUrl(objId).then(res => {
      if (res) {
        window.open(res, '_blank');
      }
    });
  })
  return cell;
};

export default temporaryPublicUrl;
