<template>
  <div id="app">
    <svg-sprite />
    <transition name="fade" mode="out-in">
      <component v-bind:is="layout">
        <slot />
      </component>
    </transition>
  </div>
</template>

<script>
import SvgSprite from '@/components/common/SvgSprite.vue';
import getUrlParams from '@/lib/getUrlParams';

export default {
  name: "App",
  components: {SvgSprite},
  data() {
    return {
      currentLayout: 'empty-layout'
    }
  },
  computed: {
    layout() {
      return this.$route.meta.emptyLayout ? 'empty-layout' : 'default-layout';
    }
  },
  methods: {
    parseUrl() {
      const params = getUrlParams();
      const filters = [];
      let orderName = '';
      let orderDirection = '';
      let orderByCalcSignalName = '';
      let orderByCalcSignalDirection = '';

      for (let [key, value] of Object.entries(params)) {
        if (key == 'polygon_id') {
          this.$store.commit('setPolygonId', value);
        } else if (key == 'group_id') {
          this.$store.commit('setGroupId', value);
        } else if (key == 'distinct') {
          this.$store.commit('setDistinct', value);
        } else if (key == 'order_name') {
          orderName = value;
        } else if (key == 'order_direction') {
          orderDirection = value;
        } else if (key == 'order_by_calculation_signal_name') {
          orderByCalcSignalName = value;
        } else if (key == 'order_by_calculation_signal_direction') {
          orderByCalcSignalDirection = value;
        } else if (key != 'investment_object_data_id' && key != 'tab') {
          const name = key;
          const valArr = key == 'calculationSignals' ? value.replace(/\_/,'&').split('&') : value.split('_');
          const [first, ...rest] = valArr;
          const condition = first;
          const value1 = (value.includes('between') || value.includes('notBetween')) ? decodeURIComponent(rest[0]) : decodeURIComponent(rest.join('_'));
          const value2 = (value.includes('between') || value.includes('notBetween')) ? rest[1] : null;

          const objInner = Object.assign({}, objInner, { name, condition, value: value1, value2 });

          filters.push(objInner);
        }
      }

      if (orderName.length && orderDirection.length) {
        this.$store.commit('updateOrder', { field: orderName, direction: orderDirection });
      }

      if (orderByCalcSignalName.length && orderByCalcSignalDirection.length) {
        this.$store.commit('updateOrderByCalcSignals', { field: orderByCalcSignalName, direction: orderByCalcSignalDirection });
      }

      if (filters.length != 0) {
        this.$store.commit('applyFilter', filters);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.parseUrl();
    });
  }
};
</script>
