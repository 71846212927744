import gql from 'graphql-tag';
import grid_constants from '@/assets/data/grid_constants';

export const buildingMaterialTypes = gql`
  query buildingMaterialTypes {
    buildingMaterialTypes
  }
`;

export const investmentObjectCurrentTasks = gql`
  query investmentObjectCurrentTasks {
    investmentObjectCurrentTasks
  }
`;

export const investmentObjectRepairTypes = gql`
  query investmentObjectRepairTypes {
    investmentObjectRepairTypes
  }
`;

export const calculationPolygons = gql`
  query calculationPolygons {
    calculationPolygons {
      id
      name
    }
  }
`;

export const investmentObjectChangeFields = gql`
  query investmentObjectChangeFields {
    investmentObjectChangeFields
  }
`;

export const investmentObjectChecks = gql`
  query investmentObjectChecks {
    investmentObjectChecks {
      mutationFieldName
      name
      values
    }
  }
`;

export const investmentObjectOrderFields = gql`
  query investmentObjectOrderFields {
    investmentObjectOrderFields
  }
`;

export const investmentObjectFilterFields = gql`
  query investmentObjectFilterFields {
    investmentObjectFilterFields
  }
`;

export const favouriteObjectsFields = (args) => `
  discountFromPriceMarket(asPercent: true)
  externalUrl
  id
  address
  price(absolute: true)
  flatInfo {
    area
    areaKitchen
    areaLiving
    floorNumber
    repairType
    roomsCount
    balconiesCount
    loggiasCount
    isApartments
    number
    kadNumber
    windowViewType
  }
  changedFields
  calculationDate
  workflowScopes
  planViewingAt
  comment
  commentAtInfo {
    at
    days(roundingAccuracy: 2)
  }
  commentMainManager
  commentMainManagerAtInfo {
    at
    days(roundingAccuracy: 2)
  }
  commentDevelopersOnAlgorithmMistake
  commentDevelopersOnAlgorithmMistakeAtInfo {
    at
    days(roundingAccuracy: 2)
  }
  commentAdmin
  commentAdminAtInfo {
    at
    days(roundingAccuracy: 2)
  }
  commentValuer
  commentValuerAtInfo {
    at
    days(roundingAccuracy: 2)
  }
  priceFactorChanges
  published
  ratingByExpert
  potentialPrices(onlyChanges: ["repair_type"]) {
    margin(roundingAccuracy: 4)
    priceMarket(roundingAccuracy: 0)
    priceMarketPerArea(roundingAccuracy: 0)
    costUpgradesAndAdvForSaleAmount(roundingAccuracy: 0)
  }
  buildingInfo {
    materialType
    constructionYear
    floorsCount
    newFinishDateYear
    rating
    ratingComment
  }
  duration {
    duration
    unit
  }
  phones
  priceMarket(roundingAccuracy: 0)
  priceMarketPerArea(roundingAccuracy: 0)
  pricePurchase(roundingAccuracy: 0)
  projectProfitYearRate(asPercent: true, roundingAccuracy: 2)
  pricePerArea(roundingAccuracy: 0)
  priceByValuer
  priceShownObviousAd
  externalFirstCrawlerAt
  jkInfo {
    displayName
  }
  publicUrl(freezeCalculationVersion: true)
  viewingReport
  currentTask
  calculationSignals
  viewScore
  exposition {
    days
  }
  costUpgradesAndAdvForSaleAmount(roundingAccuracy: 0)
  margin(asPercent: true, roundingAccuracy: 2)
  legalCheckReport
  legalCheckReportAt
  docsUrl
  sellerInfo {
    name
    externalId
  }
  priceSold
  priceSoldPerArea
  soldAt
  calcClaimSource
  calcClaimUrl
  calcClaimUtmParams
  editedAtInfo {
    at
    days(roundingAccuracy: 2)
  }
  partner{
    id
    name
  }
  clientObjectFeedback {
    message
    phone
    createdAt
    hasAnswer
    needAnswer
  }
  client {
    comment
    name
    phones(show: ${args.showPhones}) {
      canSendSms
      comment
      phone
    }
    tags {
      id
      isActive
      name
      useScopes
    }
    id
  }
  calculationCheckUrl
  externalDescription
  assignedUser {
    email
    contactInfo {
      telegram
    }
  }
  assignedUserAtInfo {
    at
  }
  tags
  workflowScopePendingFirstAtInfo {
    at
  }
  coordinate {
    lat
    lng
  }
  commentChecksForPublic
`;

const checkValues = `
  field {
    description
    group {
      description
      label
      name
    }
    label
    mutationName
    name
    isVisible
    type
    values {
      description
      label
      name
    }
  }
  groupName
  name
  value
`;

export const favouriteObjects = (args) => gql`
  query favouriteObjects (
                 $cursor: String,
                 $first: Int,
                 $scope: SelectScopeEnum,
                 $calculationPolygonId: ID,
                 $groupId: ID,
                 $queryId: ID,
                 $fields: [InternalAdmin__InvestmentObject__FilterField!],
                 $orders: [InternalAdmin__InvestmentObject__Order!],
                 $orderByCalculationSignals: [InternalAdmin__InvestmentObject__OrderByCalculationSignal!],
                 $objectType: InternalAdmin__InvestmentObject__ObjectTypeEnum,
                 $accessScopes: [AccessScopeEnum!],
                 $fieldNames: [String!]
                 $changeFields: [ChangeFieldInputValue!],
                 $distinct: [InternalAdmin__InvestmentObject__DistinctFieldEnum!]) {
    investmentObjects(
      first: $first,
      after: $cursor,
      scope: $scope,
      filter: {
        calculationPolygonId: $calculationPolygonId,
        groupId: $groupId,
        fields: $fields,
        queryId: $queryId
      },
      orders: $orders,
      orderByCalculationSignals: $orderByCalculationSignals,
      objectType: $objectType,
      distinct: $distinct
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      nodes {
        ${favouriteObjectsFields({ showPhones: args.showPhones, type: args.type })}
        checkValues (
          fieldNames: $fieldNames,
          changeFields: $changeFields
        ) {
          ${checkValues}
        }
        secureToken(
          accessScopes: $accessScopes
          expiresIn: ${grid_constants.secureTokenExpiresIn}
        )
        __typename
        ... on InternalAdmin__InvestmentObject__CalcClaim {
          commentFromCalcClaim
        }
        ... on InternalAdmin__InvestmentObject__CalcClaimForRoleUser {
          commentFromCalcClaim
        }
      }
    }
  }
`;

export const datasetInfo = gql`
  query datasetInfo ($cursor: String,
                 $scope: SelectScopeEnum,
                 $calculationPolygonId: ID,
                 $groupId: ID,
                 $queryId: ID,
                 $fields: [InternalAdmin__InvestmentObject__FilterField!],
                 $orders: [InternalAdmin__InvestmentObject__Order!],
                 $orderByCalculationSignals: [InternalAdmin__InvestmentObject__OrderByCalculationSignal!],
                 $objectType: InternalAdmin__InvestmentObject__ObjectTypeEnum) {
    investmentObjects(
      after: $cursor,
      scope: $scope,
      filter: {
        calculationPolygonId: $calculationPolygonId,
        groupId: $groupId,
        fields: $fields,
        queryId: $queryId
      },
      orders: $orders,
      orderByCalculationSignals: $orderByCalculationSignals,
      objectType: $objectType
    ) {
      count
      statsR2(asPercent: true, roundingAccuracy: 2)
      statsR2ItemsCount(lastDays: 30)
      statsGeoUniqCounts
    }
  }
`;

export const investmentObjectCalculationLog = gql`
  query investmentObjectCalculationLog ($investmentObjectId: ID) {
    calculationLog(investmentObjectId: $investmentObjectId)
  }
`;

export const investmentObjectCalculationLogs = gql`
  query investmentObjectCalculationLogs ($investmentObjectId: ID!) {
    calculationLogs(investmentObjectId: $investmentObjectId) {
      calculationDate
      investmentObjectDataId
      priceMarket(roundingAccuracy: 0)
    }
  }
`;

export const investmentObjectCalculationLogObject = gql`
  query investmentObjectCalculationLogObject ($investmentObjectId: ID!, $investmentObjectDataId: ID!) {
    calculationLogObject(
      investmentObjectId: $investmentObjectId,
      investmentObjectDataId: $investmentObjectDataId,
    ) {
      value
      investmentObjectDataId
      calculationDate
    }
  }
`;

export const investmentObjectDetailScope = gql`
  query investmentObjectDetailScope (
    $id: ID!,
    $fieldNames: [String!]
    $changeFields: [ChangeFieldInputValue!]
  ) {
    investmentObject(id: $id) {
      ${favouriteObjectsFields({ showPhones: false })}
      checkValues (
        fieldNames: $fieldNames,
        changeFields: $changeFields
      ) {
        ${checkValues}
      }
      __typename
      ... on InternalAdmin__InvestmentObject__CalcClaim {
        commentFromCalcClaim
      }
      ... on InternalAdmin__InvestmentObject__CalcClaimForRoleUser {
        commentFromCalcClaim
      }
    }
  }
`;

export const publicUrl = gql`
  query publicUrl (
    $investmentObjectId: ID,
    $expiresIn: Int) {
    publicUrl(
      investmentObjectId: $investmentObjectId,
      expiresIn: $expiresIn
    )
  }
`;

export const secureToken = gql`
  query secureToken (
    $investmentObjectId: ID,
    $expiresIn: Int,
    $accessScopes: [AccessScopeEnum!]) {
    secureToken(
      investmentObjectId: $investmentObjectId,
      expiresIn: $expiresIn,
      accessScopes: $accessScopes
    )
  }
`;

export const investmentObjectSecureToken = gql`
  query investmentObjectDetailScope (
    $investmentObjectId: ID!,
    $expiresIn: Int,
    $accessScopes: [AccessScopeEnum!]) {
    investmentObject(id: $investmentObjectId) {
      ${favouriteObjectsFields({ showPhones: false })}
      secureToken(
        expiresIn: $expiresIn,
        accessScopes: $accessScopes
      )
    }
  }
`;

export const investmentObjectTypes = gql`
  query investmentObjectTypes {
    investmentObjectTypes
  }
`;

export const investmentObjectGroups = gql`
  query investmentObjectGroups {
    investmentObjectGroups {
      id
      name
    }
  }
`;

export const calculationClaimStatus = gql`
  query calculationClaimStatus (
    $ids: [ID!]!
  ) {
    calculationClaimStatus(
      ids: $ids
    ) {
      id
      status
    }
  }
`;

export const statistics = (field) => gql`
  query statistics (
    $calculationPolygonId: ID,
    $viewScope: Types__InternalAdmin__Statistics__ViewScopeEnum!,
    $lastCountViewScope: Int!
  ) {
    statistics(
      filter: {
        calculationPolygonId: $calculationPolygonId,
      },
      viewScope: $viewScope,
      lastCountViewScope: $lastCountViewScope
    ) {
      ${field} {
        value
        day
        month
        year
      }
    }
  }
`;

export const objectId = () => gql`
  query getObjectId ($fields: [InternalAdmin__InvestmentObject__FilterField!]) {
    investmentObjects(
      filter: {
        fields: $fields
      },
    ) {
      nodes {
        id
      }
    }
  }
`;

export const userInfo = gql`
  query userInfo {
    userInfo {
      isAdmin
      user {
        permissions {
          canViewCalcClaim
        }
      }
      roles
      brokerAssignInfo {
        settingCountAssignObjectsInDay
        settingCountObjectsInWork
      }
    }
    availableUserRoles
  }
`;

export const investmentObjectImagesRepairScore = gql`
  query investmentObjectImagesRepairScore ($id: ID!) {
    investmentObjectImagesRepairScore(id: $id) {
      algorithmVersion
      scores
      images {
        imageUrl
        scores
        sourceImageUrl
      }
      status
    }
  }
`;

export const queries = gql`
  query queries ($types: [TypeEnum!]) {
    queries(types: $types) {
      default
      filters {
        condition
        name
        value
        value2
      }
      id
      name
    }
  }
`;

export const queryTypesCanCreate = gql`
  query queryTypesCanCreate {
    queryTypesCanCreate
  }
`;

export const taskTypes = gql`
  query taskTypes (
    $locale: LocaleEnum,
    $onlyActive: Boolean
  ) {
    taskTypes(
      locale: $locale,
      onlyActive: $onlyActive
    ) {
      active
      code
      codeGraqphql
      id
      localeEn
      localeRu
    }
  }
`;

export const investmentObjectCheckFields = gql`
  query investmentObjectCheckFields {
    investmentObjectCheckFields {
      mutationName
      values {
        label
        name
      }
    }
  }
`;
