const users = {
  namespaced: true,
  state: {
    users: [],
    userEmailIsVisible: false
  },

  mutations: {
    addUsers(state, users) {
      state.users = users;
    },

    obscureUserEmail(state, userEmailIsVisible) {
      state.userEmailIsVisible = userEmailIsVisible;
    }
  },

  getters: {
    getUsers(state) {
      return state.users;
    },

    getUserEmailVisibilityState(state) {
      return state.userEmailIsVisible;
    }
  },
};

export default users;
