import * as apolloQueries from '../client/queries.js';
import errorHandler from '@/lib/ErrorHandler';
import showClientPhones from '@/lib/showClientPhones.js';

export class ClientDataMapper {
  #apolloClient = null;
  #clientTags = [];
  #clientId = null;
  #clientPhones = null;

  constructor(apolloClient) {
    this.#apolloClient = apolloClient;
  }

  get clientTags() {
    return this.#clientTags;
  }

  async getClientTags(args) {
    const x = await this.#apolloClient.query({
      query: apolloQueries.query_client_tags,
      variables: {
        all: args.all,
        active: args.active,
      },
    })
    .then((data, loading) => {
      if (!loading) {
        this.#clientTags = Array.from(data.data.clientTags, el => {
          return { name: el.name, id: +el.id }
        });
        return this.#clientTags;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, args);
    });

    return x;
  }

  async changeTags(args) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_clients_tags_change,
      variables: {
        id: this.#clientId,
        tags: args.tags,
      },
    })
    .then((data, loading) => {
      if (!loading) {
        const status = data.data.clientTagsChange.status;
        if (!status) {
          return errorHandler.handleError('Не удалось изменить теги у клиента', {
            clientId: this.#clientId,
            tags: args.tags
          });
        }

        return status;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, args);
    });

    return x;
  }

  phoneIsHidden(phones) {
    if (!phones) return;

    return phones.some(el => el.phone == '***');
  }

  clientPhonesObject(phones) {
    if (!this.#clientPhones) return phones;

    return phones.map((el, index) => {
      return { ...el, phone: this.#clientPhones[index] }
    })
  }

  async updateClient(args, id) {
    if (this.phoneIsHidden(args.phones)) {
      await showClientPhones(id).then(res => {
        if (res) {
          this.#clientPhones = res;
        } else {
          errorHandler.handleError(`Не удалось получить список телефонов клиента для объекта с id ${id}`);
        }
      }).catch((err) => {
        errorHandler.handleError(err, this.id);
        alert(`Не удалось получить список телефонов клиента для объекта с id ${id}`);
      })
    }
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_client_update,
      variables: {
        id: this.#clientId,
        comment: args.comment,
        name: args.name,
        phones: this.clientPhonesObject(args.phones),
        tags: args.tags
      },
    })
    .then((data, loading) => {
      if (!loading) {
        const status = data.data.clientUpdate.status;
        if (!status) {
          return errorHandler.handleError('Не удалось изменить данные у клиента', {
            ...args,
            clientId: this.#clientId,
          });
        }

        return status;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, { ...args, id: this.#clientId, message: 'Не удалось изменить данные у клиента' });
    });

    return x;
  }

  async investmentObjectChangeClientInfo(args) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_investment_object_change_client_info,
      variables: {
        investmentObjectId: args.investmentObjectId,
        phone: args.phone,
        tags: args.tags
      },
    })
    .then((data, loading) => {
      if (!loading) {
        const status = data.data.investmentObjectChangeClientInfo.status;
        if (!status) {
          alert('Не удалось добавить изменения. Попробуйте, пожалуйста, немного позже или сообщите администратору');
          return errorHandler.handleError('Не удалось изменить данные клиента у инвестиционного объекта', args);
        }

        return status;
      }
    })
    .catch((error) => {
      alert('Не удалось добавить изменения. Попробуйте, пожалуйста, немного позже или сообщите администратору');
      return errorHandler.handleError(error, args);
    });

    return x;
  }

  setClientId(clientId) {
    this.#clientId = clientId;
  }
}
  