import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import { apolloProvider, apolloClient } from '@/lib/apollo';
import { provide } from '@vue/composition-api';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueScrollTo from 'vue-scrollto';
import router from './router';
import store from './store';
import '@babel/polyfill';
import './scss/main.global.scss';

// Vue editable grid component and styles
import VueEditableGrid from 'vue-editable-grid';
import 'vue-editable-grid/dist/VueEditableGrid.css';

import Empty from './layouts/Empty.vue';
import Default from './layouts/Default.vue';
import SvgIcon from './components/common/SvgIcon.vue';
import TextareaAutosize from 'vue-textarea-autosize';
import VueHtmlToPaper from 'vue-html-to-paper';
import vSelect from 'vue-select';

import '@/lib/sentry';

import appContext from './AppInit';

const basePath = process.env.VUE_APP_DOMAIN;

if(window.location.toString().match(/\/\/.*?\/%23\//)) window.location.replace(window.location.toString().replace('%23', '#'));

const htmlToPaperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'scrollbars=no',
    'location=no'
  ],
  styles: [
    `${basePath}/print.css`
  ],
  timeout: 100000000,
  autoClose: false
};

Vue.component('empty-layout', Empty);
Vue.component('default-layout', Default);
Vue.component('svg-icon', SvgIcon);
Vue.component('vue-editable-grid', VueEditableGrid);
Vue.component('v-select', vSelect)

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo);
Vue.use(TextareaAutosize);
Vue.use(VueHtmlToPaper, htmlToPaperOptions);

appContext.init(() => {
  new Vue({
    router,
    store,
    apolloProvider,
    setup() {
      provide(DefaultApolloClient, apolloClient)
    },
    render: (h) => h(App),
  }).$mount('#app');
});
