import Handsontable from 'handsontable';
import moment from 'moment';

const momentFormat = 'DD.MM.YYYY HH:mm';
const momentMask = '__.__.____ __:__';

export default class DateTimeEditor extends Handsontable.editors.TextEditor {
  getValue() {
    return this.TEXTAREA.value && this.TEXTAREA.value != momentMask ? moment(this.TEXTAREA.value, momentFormat).format() : null;
  }
  
  setValue(value) {
    this.TEXTAREA.value = value ? moment(value).format(momentFormat) : null;
  }

  createElements() {
    super.createElements();

    this.TEXTAREA = this.hot.rootDocument.createElement('input');
    this.TEXTAREA.setAttribute('data-hot-input', true);
    this.TEXTAREA.setAttribute('data-mask', true);
    this.textareaStyle = this.TEXTAREA.style;
    this.textareaStyle.width = 200;
    this.textareaStyle.height = 0;

    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }
}