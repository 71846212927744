import errorHandler from '@/lib/ErrorHandler';
import * as dataTableHelpers from '@/lib/dataTableHelpers.js';
import showPhones from '@/lib/showPhones.js';

const phones = async (instance, td, row, col, prop, value, cellProperties) => {
  if (!value || !Array.isArray(value)) {
    td.innerHTML = '';
    return;
  }

  td.className = 'htDimmed';

  let phones = value;

  if (value && value.every(el => !el.includes('***'))) {
    td.innerHTML = dataTableHelpers.callLink(phones);
    return;
  }

  const id = instance.getCell(row, 1).textContent;
  const showPhoneBtn = document.createElement('a');
  showPhoneBtn.className = 'show-phones ml-1';
  showPhoneBtn.dataset.objectId = id;
  showPhoneBtn.innerHTML = '👁';
  showPhoneBtn.title = 'Показать';

  showPhoneBtn.addEventListener('click', (e) => {
    showPhoneBtn.innerHTML = '⧗';
    showPhoneBtn.title = 'Подождите';

    showPhones(id).then(res => {

      if (res) {
        td.innerHTML = dataTableHelpers.callLink(res);
        return;
      } else {
        errorHandler.handleError(`Не удалось получить список телефонов для заявки с id ${id}`);
        showPhoneBtn.innerHTML = '👁';
        showPhoneBtn.title = 'Показать';
      }
    }).catch((err) => {
      errorHandler.handleError(err, id);
      alert(`Не удалось получить список телефонов для заявки с id ${id}`);
    })
  });

  td.innerHTML = '';
  td.append(phones);
  td.append(showPhoneBtn);
}
  
export default phones;
