import { apolloClient } from '@/lib/apollo';
import * as internalAdmin from '@/models/internalAdmin';

async function taskTypes() {
  const x = apolloClient.query({
    query: internalAdmin.taskTypes,
    variables: {
      locale: 'ru',
      onlyActive: false
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.taskTypes;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default taskTypes;
