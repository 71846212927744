import Vue from "vue";
import Vuex from "vuex";
import investmentObjectFields from './investment-object-fields/index';
import users from './users/index';
import clients from './clients/index';
import partners from './partners/index';
import * as constants from '@/assets/data/constants';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    polygonId: null,
    groupId: '',
    filters: [],
    scope: 'all',
    objects: [],
    tableObjects: [],
    endCursor: null,
    hasNextPage: null,
    dataHasChanged: false,
    orders: [],
    orderByCalcSignals: [],
    objectType: '',
    datasetInfo: {},
    isAdmin: null,
    queryId: null,
    activeCode: '',
    userPermissions: {},
    availableUserRoles: [],
    currentRole: null,
    countVisibleInvestmentObjects: constants.COUNT_VISIBLE_OBJECTS,
    brokerAssignInfo: null,
    distinct: null
  },
  mutations: {
    setPolygonId(state, polygonId) {
      state.polygonId = polygonId;
    },
    setGroupId(state, groupId) {
      state.groupId = groupId;
    },
    setQueryId(state, queryId) {
      state.queryId = queryId;
    },
    applyFilter(state, payload) {
      state.filters = payload;
    },
    addFilter(state, payload) {
      state.filters = [payload, ...state.filters]
    },
    updateFilter(state, payload) {
      state.filters = [
        ...state.filters.map(item => {
          if (item.name != payload.name) {
            return item
          } else {
            return {...item, ...payload}
          }
        })
      ]

      if (state.filters.every(el => el.name != payload.name)) {
        state.filters.unshift(payload);
      }
    },
    deleteFilter(state, payload) {
      state.filters = [
        ...state.filters.filter((item) => item.name != payload.name)
      ]
    },
    resetFilters(state) {
      state.filters = []
    },
    updateOrder(state, payload) {
      const arr = [];
      arr.push(payload);
      state.orders = arr;
    },
    updateOrderByCalcSignals(state, payload) {
      const arr = [];
      arr.push(payload);
      state.orderByCalcSignals = arr;
    },
    resetOrderByCalcSignals(state) {
      state.orderByCalcSignals = [];
    },
    resetOrder(state) {
      state.orders = [];
    },
    changeScope(state, payload) {
      state.scope = payload;
    },
    changeObject(state, payload) {
      Object.assign(state.objects[payload.i], { ...payload.investmentObject });
    },
    updateObjects(state, payload) {
      state.objects = state.objects.length == 0 || state.dataHasChanged == true ? payload : [...state.objects, ...payload];
    },
    updateTableObjects(state, payload) {
      state.tableObjects = payload;
    },
    updateEndCursor(state, payload) {
      state.endCursor = payload;
    },
    setHasNextPage(state, payload) {
      state.hasNextPage = payload;
    },
    changeData(state, payload) {
      state.dataHasChanged = payload;
    },
    setObjectType(state, payload) {
      state.objectType = payload;
    },
    setDatasetInfo(state, payload) {
      if (payload != null) {
        state.datasetInfo = {
          count: payload.count,
          statsR2: payload.statsR2,
          statsR2ItemsCount: payload.statsR2ItemsCount,
          statsGeoUniqCounts: payload.statsGeoUniqCounts
        }
      } else {
        this.state.datasetInfo = {};
      }
    },
    updateUserStatus(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    updateUserPermissions(state, permissions) {
      state.userPermissions = permissions
    },
    setActiveCode(state, activeCode) {
      state.activeCode = activeCode;
    },
    changeAvailableUserRoles(state, roles) {
      state.availableUserRoles = roles;
    },
    changeCurrentRole(state, role) {
      state.currentRole = role;
    },
    changeCountVisibleInvestmentObjects(state, countVisibleInvestmentObjects) {
      state.countVisibleInvestmentObjects = countVisibleInvestmentObjects;
    },
    changeBrokerAssignInfo(state, brokerAssignInfo) {
      state.brokerAssignInfo = brokerAssignInfo;
    },
    setDistinct(state, distinct) {
      if (!distinct) return;
      state.distinct = distinct.split(',');
    }
  },
  actions: {},
  getters: {
    getPolygonId: (state) => state.polygonId,
    getGroupId: (state) => state.groupId,
    getFilters: (state) => state.filters,
    getOrders: (state) => state.orders,
    getScope: (state) => state.scope,
    getObjects: (state) => state.objects,
    getEndCursor: (state) => state.endCursor,
    getHasNextPage: (state) => state.hasNextPage,
    getDataChangedState: (state) => state.dataHasChanged,
    getOrderByCalculationSignals: (state) => state.orderByCalcSignals,
    getUrlParams: (state) => state.getUrlParams,
    getObjectType: (state) => state.objectType,
    getDatasetInfo: (state) => state.datasetInfo,
    isAdminRole: (state)  => state.isAdmin,
    getQueryId: (state) => state.queryId,
    getActiveCode: (state) => state.activeCode,
    canUserViewCalcClaims(state) {
      if(state.userPermissions && state.userPermissions.canViewCalcClaim) {
        return true;
      }

      return state.isAdmin;
    },
    getTableObjects: (state) => state.tableObjects,
    getAvailableUserRoles: (state) => state.availableUserRoles,
    getCurrentRole: (state) => state.currentRole,
    getCountVisibleInvestmentObjects: (state) => state.countVisibleInvestmentObjects,
    getBrokerAssignInfo: (state) => state.brokerAssignInfo,
    getDistinct: (state) => state.distinct,
  },
  modules: {
    investmentObjectFields,
    users,
    clients,
    partners
  }
});
