import { apolloClient } from '@/lib/apollo';
import * as internalAdmin from '@/models/internalAdmin';
import errorHandler from '@/lib/ErrorHandler';

async function userInfo() {
  const x = apolloClient.query({
    query: internalAdmin.userInfo,
    fetchPolicy: 'no-cache'
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data;
    }
  })
  .catch((error) => {
    return errorHandler.handleError(error);
  });

  return x;
}

export default userInfo;
