import * as Sentry from '@sentry/browser';

class ErrorHandler {
  handleError(error, args) {
    Sentry.captureException(new Error(error), scope => {
      scope.clear();
      scope.setContext('additional-info', args);
      return scope;
    });

    return { error, args }
  }
}

const errorHandler = new ErrorHandler();

export default errorHandler;
