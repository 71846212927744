<template>
  <div class="select-wrap">
    <select class="custom-select"
            v-model="polygon"
            v-on:change="onChange($event)">
      <option value="polygon_id_all">
        Все
      </option>
      <option disabled>------------------------------------</option>
      <option v-for="el in calcPolygons"
              v-bind:key="`polygon_id_${el.id}`"
              v-bind:value="`polygon_id_${el.id}`">
        {{ el.name }}
      </option>
      <option disabled v-if="objectGroups.length != 0">------------------------------------</option>
      <option v-for="el in objectGroups"
              v-bind:key="`group_id_${el.id}`"
              v-bind:value="`group_id_${el.id}`">
        {{ el.name }}
      </option>
    </select>
  </div>
</template>

<script>
import * as internalAdmin from '@/models/internalAdmin';
import updateParam from '@/mixins/updateParam';
import { mapGetters } from 'vuex';

const DEFAULT_POLYGON_ID = 'all';

export default {
  mixins: [updateParam],
  data() {
    return {
      polygon: null,
      calcPolygons: [],
      objectGroups: [],
    }
  },
  computed: {
    ...mapGetters([
      'getPolygonId',
      'getGroupId',
    ])
  },
  methods: {
    onChange(event) {
      const isAll = event.target.value === 'polygon_id_all';
      const isPolygon = this.calcPolygons.filter(obj => `polygon_id_${obj.id}` === event.target.value);
      const isGroup = this.objectGroups.filter(obj => `group_id_${obj.id}` === event.target.value);

      if (isAll) {
        const id = 'all';
        this.updateParam({ storeKey: 'setPolygonId', lsKey: 'polygon_id', v: '' });
        this.updateParam({ storeKey: 'setGroupId', lsKey: 'group_id', v: '' });
        this.polygon = `polygon_id_${id}`;
        this.$store.commit('setActiveCode', 'showQueries');
      } else if (isPolygon.length != 0) {
        const id= isPolygon[0].id;
        this.updateParam({ storeKey: 'setPolygonId', lsKey: 'polygon_id', v: id });
        this.updateParam({ storeKey: 'setGroupId', lsKey: 'group_id', v: '' });
        this.polygon = `polygon_id_${id}`;
        this.$store.commit('setActiveCode', 'showQueries');
      } else if (isGroup.length != 0) {
        const id= isGroup[0].id;
        this.updateParam({ storeKey: 'setGroupId', lsKey: 'group_id', v: id });
        this.updateParam({ storeKey: 'setPolygonId', lsKey: 'polygon_id', v: '' });
        this.polygon = `group_id_${id}`;
        this.$store.commit('setActiveCode', 'createNewRecords');
      }

      this.$store.commit('changeData', true);
    }
  },
  apollo: {
    calcPolygons: {
      query: internalAdmin.calculationPolygons,
      update: data => data.calculationPolygons
    },
    objectGroups: {
      query: internalAdmin.investmentObjectGroups,
      update: data => data.investmentObjectGroups,
    }
  },
  created() {
    const areaId = this.$route.query.polygon_id || this.getPolygonId || localStorage.getItem('polygon_id');
    const groupId = this.$route.query.group_id || this.getGroupId || localStorage.getItem('group_id');

    if (!areaId && !groupId) {
      this.updateParam({ lsKey: 'polygon_id', v: '', storeKey: 'setPolygonId' });
      this.polygon = `polygon_id_${DEFAULT_POLYGON_ID}`;
    } else if (areaId) {
      this.updateParam({ lsKey: 'polygon_id', v: areaId, storeKey: 'setPolygonId' });
      this.polygon = `polygon_id_${areaId}`;
    } else if (groupId) {
      this.updateParam({ lsKey: 'group_id', v: groupId, storeKey: 'setGroupId' });
      this.polygon = `group_id_${groupId}`;
    }
  },
};

</script>

<style lang="scss" scoped>
.custom-select {
  width: 25rem;
}

@include media-breakpoint-up(lg) {
  .select-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 1320px) {
  .select-wrap {
    width: 18rem;
  }

  .custom-select {
    width: 18rem;
  }
}

@include media-breakpoint-down(lg) {
  .select-wrap {
    flex-wrap: wrap;
    width: 25rem;

    label {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .select-wrap {
    label {
      display: inline-block;
      vertical-align: top;
      padding-bottom: 0.5rem;
    }
  }

  .custom-select {
    width: 100%;
  }
}
</style>
