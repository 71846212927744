import Handsontable from 'handsontable';
import constants from '@/assets/data/grid_constants';

((Handsontable) => {
  const ratingByExpertValidator = (query, callback) => {
    callback(query > constants.ratingByExpertMinValue || !query)
  }

  const planViewingAtValidator = (query, callback) => {
    const today = new Date();
    const v = new Date(query);
    callback(v.getTime() > today.getTime() || !query);
  }

  Handsontable.validators.registerValidator('rating-by-expert', ratingByExpertValidator);
  Handsontable.validators.registerValidator('plan-viewing-at', planViewingAtValidator);

})(Handsontable);
