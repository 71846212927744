import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/lib/Auth.js';
import VueRouterBackButton from 'vue-router-back-button';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Authorization',
    component: () =>
      import('../views/Authorization.vue'),
    meta: {
      emptyLayout: true
    }
  },
  {
    path: '/all-apartments',
    name: 'AllApartments',
    component: () =>
      import('../views/AllApartments.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/pending-apartments',
    name: 'PendingApartments',
    component: () =>
      import('../views/PendingApartments.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/favourite-apartments',
    name: 'FavouriteApartments',
    component: () =>
      import('../views/FavouriteApartments.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/visits',
    name: 'Visits',
    component: () =>
      import('../views/Visits.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/legal-checks',
    name: 'LegalChecks',
    component: () =>
      import('../views/LegalChecks.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () =>
      import('../views/Portfolio.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/calculation-claims',
    name: 'CalculationClaims',
    component: () =>
      import('../views/CalculationClaims.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/algorithm-mistakes',
    name: 'AlgorithmMistakes',
    component: () =>
      import('../views/AlgorithmMistakes.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () =>
      import('../views/Statistics.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import('../views/Users.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () =>
      import('../views/Clients.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () =>
      import('../views/Partners.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/partner-create',
    name: 'PartnerCreate',
    component: () =>
      import('../views/Partner.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/partner-update/:partner_id',
    name: 'PartnerUpdate',
    component: () =>
      import('../views/Partner.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/calculation-log/:id',
    name: 'CalculationLog',
    component: () =>
      import('../views/CalculationLog.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/object-form/:id',
    name: 'ObjectForm',
    component: () =>
      import('../views/ObjectForm.vue'),
    meta: {
      defaultLayout: true
    },
    props: true
  },
  {
    path: '/lk-ur/',
    name: 'PartnerProfile',
    component: () =>
      import('../views/PartnerProfile.vue'),
    meta: {
      emptyLayout: true
    },
    children: [
      {
        path: '',
        name: 'PartnerProfileList',
        component: () =>
          import('../views/partner-profile/PartnerProfileList.vue'),
        meta: {
          emptyLayout: true
        },
      },
      {
        path: 'create-calc-claim',
        name: 'PartnerProfileCreateCalcClaim',
        component: () =>
          import('../views/partner-profile/PartnerProfileCreateCalcClaim.vue'),
        meta: {
          emptyLayout: true
        },
      },
      {
        path: 'calc-claim-result',
        name: 'PartnerProfileCalcClaimResult',
        component: () =>
          import('../views/partner-profile/PartnerProfileCalcClaimResult.vue'),
        meta: {
          emptyLayout: true
        },
      }
    ]
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueRouterBackButton, { router });

router.beforeEach((to, from, next) => {
  if (to.name === 'Authorization' && auth.isAuth) {
    next({ name: 'Portfolio' });
    return;
  }
  if (to.name != 'Authorization' && !auth.isAuth) {
    if (to.query.token) {
      next();
      return;
    }
    next({ name: 'Authorization' });
  } else {
    next();
  }
});

export default router;
