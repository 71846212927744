import moment from 'moment';
const momentFormat = 'DD.MM.YYYY HH:mm';
import store from '@/store';

export const formatSelect = (arr) => {
  const newArr = [];
  arr.forEach(el => {
    newArr.push(el.text)
  })
  return newArr;
};

export const formatPhone = (phone) => {
  const isRU = phone.startsWith('+7') || phone.startsWith('7');
  if ((phone.charAt(0) == '+' && phone.length > 15) || !isRU) return phone;

  return `+${phone.slice(-11, -10)} ${phone.slice(-10, -7)} ${phone.slice(-7, -4)}-${phone.slice(-4, -2)}-${phone.slice(-2)}`
}

export const getLocalTime = (v) => {
  const utcTime = moment.utc(v);
  return v != null && v ? moment(utcTime).local().format(momentFormat) : '';
}

export const setFieldReadOnly = (role) => {
  if (store.getters.isAdminRole || store.getters.getCurrentRole.includes('admin')) return false;
  if (!store.getters.getCurrentRole) return true;
  if (Array.isArray(role) && role.length > 1) {
    const arr = [role, store.getters.getCurrentRole];

    const result = arr.shift().reduce((res, v) => {
      if (res.indexOf(v) === -1 && arr.every((a) => {
        return a.indexOf(v) !== -1;
      })) res.push(v);
      return res;
    }, []);

    return result.length == 0;
  }
  return !store.getters.getCurrentRole.includes(role);
}

export const checkHasRole = (role) => {
  return store.getters.getCurrentRole.includes(role);
}

export const callLink = (v) => {
  if (!Array.isArray(v)) return `<a href="tel:${v}">${v}</a>`;
  let res = '';

  v.forEach((el, i) => {
    res += `<a href="tel:${el}">${el}</a>`;
    if (i != v.length - 1) res += ', ';
  });

  return res;
}
