import queryInvestmentObjectClientPhones from '@/lib/queries/investment_object_client_phones.js';
import { apolloClient } from '@/lib/apollo';

const showClientPhones = (id) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: queryInvestmentObjectClientPhones,
        variables: {
          id
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        if (!res.data.investmentObject.client) return;

        const phones = Array.from(res.data.investmentObject.client.phones, x => x.phone);

        resolve(phones);
      }).catch((err) => { reject(err) });
  });
}

export default showClientPhones;
