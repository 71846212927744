const clients = {
  namespaced: true,
  state: {
    clientInstance: null,
    clients: [],
    filters: []
  },

  mutations: {
    createClientInstance(state, clientInstance) {
      state.clientInstance = clientInstance;
    },

    setClients(state, clients) {
      state.clients = clients;
    },

    applyFilter(state, payload) {
      state.filters = payload;
    },
  },

  getters: {
    getClientInstance(state) {
      return state.clientInstance;
    },

    getClients(state) {
      return state.clients;
    },

    getFilters(state) {
      return state.filters;
    }
  },
};

export default clients;
