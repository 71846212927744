export const materialTypeObj = [
  { value: '', text: 'Ничего не выбрано' },
  { value: 'brick', text: 'Кирпичный' },
  { value: 'monolith', text: 'Монолитный' },
  { value: 'panel', text: 'Панельный' },
  { value: 'block', text: 'Блочный' },
  { value: 'wood', text: 'Деревянный' },
  { value: 'stalin', text: 'Сталинский' },
  { value: 'monolithBrick', text: 'Кирпично-монолитный' },
  { value: 'old', text: 'Устаревший' },
  { value: 'gasSilicateBlock', text: 'Силикатные блоки' },
];

export const dataAvailabilityObj = [
  { value: 'none', text: 'Нет данных' },
  { value: 'yes', text: 'Да' },
  { value: 'no', text: 'Нет' },
];

export const repairTypeObj = [
  { value: 'no', text: 'Никакой' },
  { value: 'cosmetic', text: 'Косметический' },
  { value: 'euro', text: 'Евро' },
  { value: 'design', text: 'Дизайнерский' },
];

export const ratingListObj = [
  { value: 'Неизвестно', text: 'Неизвестно' },
  { value: 'A+', text: 'A+' },
  { value: 'A', text: 'A' },
  { value: 'A-', text: 'A-' },
  { value: 'B+', text: 'B+' },
  { value: 'B', text: 'B' },
  { value: 'B-', text: 'B-' },
  { value: 'C+', text: 'C+' },
  { value: 'C', text: 'C' },
  { value: 'C-', text: 'C-' },
  { value: 'D+', text: 'D+' },
  { value: 'D', text: 'D' },
  { value: 'D-', text: 'D-' },
  { value: 'E+', text: 'E+' },
  { value: 'E', text: 'E' },
  { value: 'E-', text: 'E-' },
  { value: 'FFF', text: 'FFF' },
];

export const repairTypeObjForm = [
  { value: null, text: 'Не интерьер' },
  { value: 'killed', text: 'Без ремонта' },
  { value: 'cosmetics', text: 'Косметика' },
  { value: 'euro', text: 'Евро' },
  { value: 'super_euro', text: 'Дизайн' }
];

export const repairRating = [
  { value: 2, text: 'Никакой' },
  { value: 5, text: 'Косметический' },
  { value: 7, text: 'Евро' },
  { value: 10, text: 'Дизайнерский' },
];

export const parkingType = [
  { value: 'in_yard', text: 'Во дворе' },
  { value: 'underground', text: 'Подземная' },
  { value: 'chaos', text: 'Хаотичная' },
  { value: 'barrier_used', text: 'За шлагбаумом' }
];

export const roomType = [
  { value: 'none', text: 'Не комната' },
  { value: 'restroom', text: 'Санузел' },
  { value: 'kitchen', text: 'Кухня' },
  { value: 'room', text: 'Жилая комната' },
];

export const statisticsCharts = [
  {
    code: 'itemsInWorkflowPending',
    viewScope: 'day',
    title: 'Объектов в "В работе"',
    seriesName: 'Объектов'
  },
  {
    code: 'itemsWithComment',
    viewScope: 'day',
    title: 'Прокомментированых объектов',
    seriesName: 'Объектов'
  },
  {
    code: 'itemsWithViewingReport',
    viewScope: 'day',
    title: 'Объектов, получивших отчет об осмотре',
    seriesName: 'Объектов'
  },
  {
    code: 'itemsWithLegalCheckReport',
    viewScope: 'day',
    title: 'Объектов, получивших отчет о юрпроверке',
    seriesName: 'Объектов'
  },
  {
    code: 'itemsWithRatingByExpert',
    viewScope: 'day',
    title: 'Объектов, оцененных экспертом',
    seriesName: 'Объектов'
  },
  {
    code: 'calcClaimCount',
    viewScope: 'day',
    title: 'Заявок',
    seriesName: 'Заявок'
  },
  {
    code: 'itemsCalcClaimPhonesUniq',
    viewScope: 'day',
    title: 'Кол-во уникальных телефонов (за день)',
    seriesName: 'Телефонов',
    dataLabels: true
  },
  {
    code: 'itemsCalcClaimPhonesUniq',
    viewScope: 'week',
    title: 'Кол-во уникальных телефонов (за неделю)',
    seriesName: 'Телефонов',
    dataLabels: true
  },
  {
    code: 'itemsCalcClaimPhonesUniq',
    viewScope: 'month',
    title: 'Кол-во уникальных телефонов (за месяц)',
    seriesName: 'Телефонов',
    dataLabels: true
  },
  {
    code: 'itemsCalcClaimPhonesUniqAll',
    viewScope: 'month',
    title: 'Кол-во уникальных телефонов за все время',
    seriesName: 'Телефонов',
    dataLabels: true
  },
];

export const queryTypes = [
  {
    name: 'user',
    label: 'Личный'
  },
  {
    name: 'fixedForUser',
    label: 'Фиксированный для пользователя'
  },
  {
    name: 'portfolio',
    label: 'Портфолио'
  },
];

export const userRoles = {
  'user': 'клиент/брокер/риелтор',
  'internal': 'ТопБрокер',
  'callCenter': 'колцентр',
  'valuer': 'оценщик',
  'lawyer': 'юрист',
  'manager': 'менеджер'
}; 

export const COUNT_VISIBLE_OBJECTS = 20;

export const COUNT_VISIBLE_USERS = 500;

export const momentFormat = 'DD.MM.YYYY HH:mm';

export const dateFilters = {
  today: 'сегодня',
  yesterday: 'вчера',
  tomorrow: 'завтра',
  now: 'сейчас',
};

export const PARTNER_CLAIM_PURPOSES = [
  {
    name: null,
    label: 'Нет целевого действия (нет кнопки)',
    scope: ['publicObjectTargetAction', 'calcClaimObjectTargetAction', 'tradeUpPublicObjectTargetAction']
  },
  {
    name: 'trade_up_map',
    label: 'Переход на трейдап карту  (сценарий обмена)',
    scope: ['calcClaimObjectTargetAction']
  },
  {
    name: 'wanna_sell',
    label: 'Продать эту квартиру (форма обратной связи)',
    scope: ['calcClaimObjectTargetAction']
  },
  {
    name: 'wanna_buy',
    label: 'Купить квартиру (форма обратной связи)',
    scope: ['calcClaimObjectTargetAction']
  },
  {
    name: 'collateral_evaluation',
    label: 'Оценка залога (форма обратной связи)',
    scope: ['calcClaimObjectTargetAction']
  },
  {
    name: 'trade_in',
    label: 'Обменять эту квартиру (форма обратной связи)',
    scope: ['tradeUpPublicObjectTargetAction']
  },
  {
    name: 'get_apt_details',
    label: 'Получить подробности о квартире (форма обратной связи)',
    scope: ['calcClaimObjectTargetAction']
  },
  {
    name: 'wanna_view',
    label: 'Записаться на просмотр (форма обратной связи с комментарием и выбором цели)',
    scope: ['publicObjectTargetAction', 'tradeUpPublicObjectTargetAction']
  },
  {
    name: 'wanna_view_only_phone',
    label: 'Записаться на просмотр (форма обратной связи)',
    scope: ['publicObjectTargetAction', 'tradeUpPublicObjectTargetAction']
  }
];

export const PARTNER_BOTTOM_BAR_BUTTONS = {
  map: 'Карта',
  shivaAi: 'shivaAi',
  deals: 'Сделки',
  propertyValuation: 'Оценка',
  tradeUp: 'Обмен',
  mapAnalytics: 'Индексы'
};

export const PARTNER_WEB_ANALYTIC_TYPES = [
  {
    name: 'yandex_metrika',
    label: 'Яндекс метрика'
  },
  {
    name: 'google_analytics',
    label: 'Гугл аналитика'
  },
  {
    name: 'facebook_pixel',
    label: 'Фейсбук пиксель'
  }
];

export const PARTNER_REALISTE_LOGO_TYPES = [
  {
    name: 'dark',
    label: 'Темный'
  },
  {
    name: 'light',
    label: 'Светлый'
  }
];

export const PARTNER_TYPES = [
  {
    name: 'for_developer',
    label: 'Для застройщиков'
  },
  {
    name: 'for_bank',
    label: 'Для банков'
  },
  {
    name: 'custom_value',
    label: 'Кастомное значение'
  }
];

export const PARTNER_COLOR_THEMES = [
  {
    name: 'dark',
    label: 'Темная'
  },
  {
    name: 'light',
    label: 'Светлая'
  }
];

export const PARTNER_ONBOARDING_SLIDES = {
  propertyValuation: 'Оценка',
  map: 'Карта',
  poi: 'Факторы',
  competitors: 'Конкуренты',
  tradeUp: 'Обмен',
  investment: 'Инвестиции',
  mapAnalytics: 'Аналитика'
};

export const PARTNER_INHERITE_RULES = {
  title: 'Правила наследования полей',
  description: 'Если у партнера есть созданные от него партнеры, то при наличии наследуемых полей, изменения в них применяться и у партнеров-детей.<br> Наследование полей при изменении проходит всю цепочку созданных партнеров-детей до конца, либо до ненаследуемого поля у партнера-ребенка'
}
