import taskTypes from '@/lib/handsontable/taskTypes';
import checkFields from '@/lib/checkFields';
import user from '@/lib/data_mappers/user.js';
import userInfo from '@/lib/userInfo';
import store from './store';
import auth from '@/lib/Auth.js';
import * as constants from '@/assets/data/constants';

class AppInit {
  async init(handler) {
    await this.updateInfo();
    if (auth.isAuth && !window.location.hash.includes('lk-ur')) {
      await this.initConstants();
    }
    handler();
  }

  resetUserInfoData() {
    store.commit('updateUserStatus', null);
    store.commit('updateUserPermissions', {});
    store.commit('changeAvailableUserRoles', []);
    store.commit('changeCurrentRole', null);
    store.commit('changeBrokerAssignInfo', null);
  }

  async initConstants() {
    await taskTypes().then((data) => {
      store.commit('investmentObjectFields/setTaskTypes', data);
    });

    await checkFields().then((data) => {
      store.commit('investmentObjectFields/setCheckFields', data);
    });
  }

  async updateInfo() {
    if (auth.credentials && Object.keys(auth.credentials).length != 0) {
      await this.userInfo();
    } else {
      this.resetUserInfoData();
      auth.clearCredentials();
    }
  }

  async userInfo() {
    await userInfo()
      .then((data) => {
        if (!data || data.error) {
          this.resetUserInfoData();
          auth.clearCredentials();
          auth.showAuthError();
          return;
        }
        store.commit('updateUserStatus', data.userInfo.isAdmin);
        store.commit('updateUserPermissions', data.userInfo.user.permissions);
        store.commit('changeAvailableUserRoles', data.availableUserRoles);
        store.commit('changeCurrentRole', data.userInfo.roles);
        store.commit('changeBrokerAssignInfo', data.userInfo.brokerAssignInfo);
      });
  }
}

const appContext = new AppInit();

export default appContext;
