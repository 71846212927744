import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
  });
}
