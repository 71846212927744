import gql from 'graphql-tag';

const CLIENT_FIELDS = `
  id
  comment
  name
  phones {
    phone
    canSendSms
    comment
  }
  tags {
    id
    isActive
    name
    useScopes
  }
`;

export const query_client_tags = gql`
  query query_client_tags(
    $active: Boolean,
    $all: Boolean
  ) {
    clientTags(
      active: $active,
      all: $all
    ) {
      id
      isActive
      name
      useScopes
    }
  }
`;

export const mutation_clients_tags_change = gql`
  mutation mutation_clients_tags_change(
    $id: ID!
    $tags : [ID!]!
  ) {
    clientTagsChange(
      input: {
        id: $id,
        tags: $tags
      }
    ) {
      client {
        id
      }
      status
    }
  }
`;

export const mutation_client_update = gql`
  mutation mutation_client_update(
    $id: ID!,
    $comment: String,
    $name: String!,
    $phones: [InternalAdmin__Client__PhoneInput!],
    $tags: [ID!]
  ) {
    clientUpdate(
      input: {
        id: $id,
        comment: $comment,
        name: $name,
        phones: $phones,
        tags: $tags
      }
    ) {
      client {
        ${CLIENT_FIELDS}
      }
      status
    }
  }
`;

export const mutation_investment_object_change_client_info = gql`
  mutation mutation_investment_object_change_client_info(
    $investmentObjectId: ID!,
    $phone: String,
    $tags: [ID!]
  ) {
    investmentObjectChangeClientInfo(
      input: {
        phone: $phone,
        tags: $tags,
        investmentObjectId: $investmentObjectId
      }
    ) {
      status
    }
  }
`;
