import errorHandler from '@/lib/ErrorHandler';
import * as dataTableHelpers from '@/lib/dataTableHelpers.js';
import showClientPhones from '@/lib/showClientPhones.js';
import { ClientDataMapper } from '@/lib/data_mappers/client/client.js';
import { apolloClient } from '@/lib/apollo';

const phones = async (instance, td, row, col, prop, value, cellProperties) => {
  if (!value) {
    td.innerHTML = '';
    return;
  }

  td.className = 'htDimmed';

  let phones = value;

  if (!value.includes('***')) {
    td.innerHTML = dataTableHelpers.callLink(phones);
    return;
  }

  const id = instance.getCell(row, 1).textContent;
  
  const showPhoneBtn = document.createElement('a');
  showPhoneBtn.className = 'show-phones ml-1';
  showPhoneBtn.innerHTML = '👁';
  showPhoneBtn.dataset.objectId = id;
  showPhoneBtn.title = 'Показать';

  const editPhoneBtn = document.createElement('a');
  editPhoneBtn.className = 'edit-phones ml-1';
  editPhoneBtn.innerHTML = '✎';
  editPhoneBtn.dataset.objectId = id;
  editPhoneBtn.title = 'Редактировать';

  showPhoneBtn.addEventListener('click', (e) => {
    showPhoneBtn.innerHTML = '⧗';
    showPhoneBtn.title = 'Подождите';

    showClientPhones(id).then(res => {

      if (res) {
        phones = dataTableHelpers.callLink(res);
        td.innerHTML = phones;
        td.append(editPhoneBtn);
        return;
      } else {
        errorHandler.handleError(`Не удалось получить список телефонов клиента для заявки с id ${id}`);
        showPhoneBtn.innerHTML = '👁';
        showPhoneBtn.title = 'Показать';
      }
    }).catch((err) => {
      errorHandler.handleError(err, id);
      alert(`Не удалось получить список телефонов клиента для заявки с id ${id}`);
    })
  });

  editPhoneBtn.addEventListener('click', (e) => {
    editPhoneBtn.innerHTML = '⧗';
    editPhoneBtn.title = 'Подождите';

    showClientPhones(id).then(res => {

      if (res) {
        editPhoneBtn.innerHTML = '✎';
        editPhoneBtn.title = 'Редактировать';

        const phone = prompt('Изменить телефон', res[0]);

        if (phone) {
          const client = new ClientDataMapper(apolloClient);

          client.investmentObjectChangeClientInfo({
            investmentObjectId: id,
            phone
          });

          phones = dataTableHelpers.callLink([phone]);
          td.innerHTML = phones;
          td.append(editPhoneBtn);
        }
      } else {
        errorHandler.handleError(`Не удалось получить список телефонов клиента для заявки с id ${id}`);
      }
    }).catch((err) => {
      errorHandler.handleError(err, id);
      alert(`Не удалось получить список телефонов клиента для заявки с id ${id}`);
    })
  });

  td.innerHTML = '';
  td.append(phones);
  td.append(showPhoneBtn);
  td.append(editPhoneBtn);
}
  
export default phones;
