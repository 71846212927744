const partners = {
  namespaced: true,
  state: {
    inheritedFields: [],
    parentId: null
  },

  mutations: {
    updateInheritedFields(state, payload) {
      state.inheritedFields = payload;
    },

    addInheritedFields(state, payload) {
      state.inheritedFields.push(payload);
    },

    removeInheritedFields(state, payload) {
      const res = state.inheritedFields.filter(el => el != payload);

      state.inheritedFields = res;
    },

    setParentId(state, payload) {
      state.parentId = payload;
    }
  },

  getters: {
    getInheritedFields(state) {
      return state.inheritedFields;
    },

    getParentId(state) {
      return state.parentId;
    }
  },
};

export default partners;
