import { apolloClient } from '@/lib/apollo';
import * as internalAdmin from '@/models/internalAdmin';

async function taskTypes() {
  const x = apolloClient.query({
    query: internalAdmin.investmentObjectCheckFields
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.investmentObjectCheckFields;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default taskTypes;
